import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Image } from 'react-bootstrap';
import {
  // amenitiesData,
  // utilitiesData,
  laundryForHouseData,
  laundryForUnitData,
  getUtilitiesAndAmenities
} from '../../Constants/propertyDetails';
import Cookies from 'js-cookie';
import { EditButton, LargeButton } from "../../App/components";
import { furnishedDataTitle, laundryDataTitle, parkingIncludeTitle, petAndPolicyTitle } from '../../Utils/PropertyDetailsData/propertyDetailsReturn';
import { useSelector } from 'react-redux';
import './style.scss';
import EditableLabel from 'react-inline-editing';

const ImportToolPreviewUnitDetails = (props) => {
  const previewDetails = props.previewDetails;
  const laundryData = (previewDetails && previewDetails.property_type === 1) ? laundryForHouseData : laundryForUnitData;
  const role = Number(Cookies.get('role'));

  const [amenitiesData, setAmenitiesData] = useState([]);
  const [utilitiesData, setUtilitiesData] = useState([]);
  const history = useHistory();
  const [isTour, setIsTour] = useState(false);
  
  const showEditUnitDetails = () => {
    
  }
  
  useEffect(() => {
    if (history.location.pathname.indexOf("tour") > 0) {
      setIsTour(true);
    }
  }, [history.location.pathname, previewDetails]);

  useEffect(() => {
    (async () => {
      const { utilitiesData = [], amenitiesData = [] } = await getUtilitiesAndAmenities()
      setAmenitiesData(amenitiesData)
      setUtilitiesData(utilitiesData)
    })()
  }, [previewDetails])

  const amenities = (role === 1 || role === 2) && amenitiesData && amenitiesData.length > 0 && amenitiesData.map((data) => {
    let title = ''
    if (previewDetails && previewDetails?.amenities?.includes(data.id)) {
      title = data.name
    }
    return title
  }).filter((el) => {
    return el !== '' && el !== null && el !== undefined
  }).join(', ')

  const utilities = (role === 1 || role === 2) && utilitiesData && utilitiesData.length > 0 && utilitiesData.map((data) => {
    let title = ''
    if (previewDetails && previewDetails?.utilities?.includes(data.id)) {
      title = data.name
    }
    return title
  }).filter((el) => {
    return el !== '' && el !== null && el !== undefined
  }).join(', ')

  const amenitiesTenant = previewDetails?.amenities?.length > 0 && previewDetails.amenities.map((data) => {
    return data
  }).filter((el) => {
    return el !== null && el !== undefined && el !== ''
  }).join(', ')

  const utilitiesTenant = previewDetails?.utilities?.length > 0 && previewDetails.utilities.map((data) => {
    return data
  }).filter((el) => {
    return el !== null && el !== undefined && el !== ''
  }).join(', ')

  return (
    <div className={role === 1 ? 'inner-wrapper for-admin' : 'inner-wrapper'}>
      <h6>Unit Details</h6>
      {(role === 2 || role === 1) &&
        <>
          {previewDetails ?
            <>
              <ul className='details'>
                <li>
                  <Image className='icon' src={require('../../_assets/images/details/furnished.svg')} alt='furnished' />
                  {previewDetails && furnishedDataTitle(previewDetails.is_furnished)}
                </li>
                <li>
                  <Image className='icon' src={require('../../_assets/images/details/pet-policy.svg')} alt='pet' />
                  {previewDetails && petAndPolicyTitle(previewDetails.pet_policy)}
                </li>
                <li>
                  <Image className='icon' src={require('../../_assets/images/details/parking.svg')} alt='parking' />
                  {previewDetails && parkingIncludeTitle(previewDetails.is_parking_included, previewDetails.parking_slot)}
                </li>
                {utilities ?
                  <li>
                    <Image className='icon' src={require('../../_assets/images/details/utility.svg')} alt='utility' />
                Utilities Included (
                  {utilities}
                  )
                </li> : ''}

                {previewDetails.laundry !== 0 ?
                  <li>
                    <Image className='icon' src={require('../../_assets/images/details/laundry.svg')} alt='laundry' />
                    {previewDetails && laundryDataTitle(laundryData, previewDetails.laundry)}
                  </li> : ''}

                {amenities ?
                  <li>
                    <Image className='icon' src={require('../../_assets/images/details/aminities.svg')} alt='aminities' />
                  Amenities (
                   {amenities}
                  )
                </li> : ''}
              </ul> </> : ''}
        </>}

      {(!role || role === 3 || (role === 2 && isTour)) &&
        <>
          {previewDetails ?
            <ul className='details'>
              <li>
                <Image className='icon' src={require('../../_assets/images/details/furnished.svg')} alt='furnished' />
                {previewDetails && furnishedDataTitle(previewDetails.is_furnished)}
              </li>
              <li>
                <Image className='icon' src={require('../../_assets/images/details/pet-policy.svg')} alt='pet' />
                {previewDetails && petAndPolicyTitle(previewDetails.pet_policy)}
              </li>
              <li>
                <Image className='icon' src={require('../../_assets/images/details/parking.svg')} alt='parking' />
                {previewDetails && parkingIncludeTitle(previewDetails.is_parking_included, previewDetails.parking_slot)}
              </li>

              {(previewDetails && previewDetails.utilities && previewDetails.utilities.length > 0 && utilitiesTenant)?
                <li>
                  <Image className='icon' src={require('../../_assets/images/details/utility.svg')} alt='utility' />
                Utilities Included ({utilitiesTenant})
              </li> : ''}

              {previewDetails.laundry !== 0 ?
                <li>
                  <Image className='icon' src={require('../../_assets/images/details/laundry.svg')} alt='laundry' />
                  {previewDetails && previewDetails.formatted_laundry} {/* {laundryDataTitle(laundryData, previewDetails.laundry)} */}
                </li> : ''}

              {previewDetails && previewDetails.amenities && previewDetails.amenities.length > 0 && amenitiesTenant ?
                <li>
                  <Image className='icon' src={require('../../_assets/images/details/aminities.svg')} alt='aminities' />
                  Amenities (
                    {amenitiesTenant}
                  )
                </li> : ''}
            </ul> : ''}
              
          <div className='button-wrapper'>
            <EditButton onClick={showEditUnitDetails} />
          </div>
        </>}
    </div>
  );
}
export default ImportToolPreviewUnitDetails;