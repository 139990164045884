import React, { Component } from "react";
import {
  Container,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import AuthLayout from "../Authentication";
import { connect } from "react-redux";
import axios from "axios";
import { Redirect } from "react-router";
import { setForgotPasswordPin, setLoggedIn } from "../../../Store/actions/AuthAction";
import { API_URL } from "../../../Constants/endPoints"
import Cookies from "js-cookie";
import { logger, toaster } from "../../../Utils";
import { validate } from "../../../Utils/Validation/validation";
import { VerificationSchema } from "./VerificationSchema";
import { postAPI } from "../../../Api";

const log = logger("Verification");
class Verification extends Component {
  state = { modalOpen: false, activation_code: null, activated: false, user_id: null, flow: null, role: null, disableBtn: false };

  componentDidMount = () => {
    if (this.props?.location?.state !== undefined) {
      if (this.props?.location?.state?.flow === 'authentication') {
        this.setState({
          user_id: this.props?.location?.state?.id,
          flow: this.props?.location?.state?.flow
        })
      } else {
        this.setState({
          user_id: this.props?.auth?.userId || this.props?.auth.id,
          flow: this.props?.location?.state?.flow
        })
      }
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props?.location?.state !== undefined) {
      if (prevProps?.location?.state?.flow !== this.props?.location?.state?.flow) {
        if (this.props?.location?.state.flow === 'authentication') {
          this.setState({
            user_id: this.props?.location?.state.id,
            flow: this.props?.location?.state?.flow
          })
        } else {
          this.setState({
            user_id: this.props?.auth?.userId || this.props?.auth.id,
            flow: this.props?.location?.state?.flow
          })
        }
      }
    }

  }
  onChangeActivationCode = (e) => {
    this.setState({
      activation_code: e.target.value,
    });
  };

  resendCode = (authData) => {
    const location_state = this.props?.location?.state;
    const token = `Bearer ${Cookies.get("access_token")}`
    const headers = {
      'Authorization': token
    }

    if(location_state.flow === 'signupflow' || location_state.flow === 'authentication'){
      const url = API_URL + '/resend-activation-code';
      const data = {
        email: authData ,
      }
      axios.post(url, data, {
        headers: headers
      })
      .then(function (response) {
        toaster("success", response.data.message);
      })
      .catch(function (error) {
        toaster('error', 'Something went wrong!')
      });
    }

    if(location_state.flow === 'forgotPassword'){
      const url = API_URL + '/forgot-password';
      const data = {
        type: location_state.type === 'email' ? 2 : 1,
      }
      if(location_state.type === 'email'){
        data.email = location_state.data
      }else{
        data.phone_number = location_state.data
      }

      axios.post(url, data, {
        headers: headers
      })
      .then(function (response) {
        toaster("success", response.data.message);
      })
      .catch(function (error) {
        toaster('error', 'Something went wrong!')
      });

    }
  }

  renderInput = (type, id, placeholder, onChange) => {
    return (
      <FormGroup style={{ position: 'relative' }}>
        <Input
          type={type}
          name={id}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
        /> <p style={{
          position: 'absolute',
          top: '15px',
          right: '11px',
          fontSize: '14px',
          fontWeight: '600',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
          onClick={() => this.resendCode(this.props?.auth?.email ? this.props?.auth?.email : this.props?.location?.state?.data)}>Resend Code</p>
      </FormGroup>
    );
  };
  renderModal = () => {
    return (
      <Modal
        isOpen={this.state.modalOpen}
        toggle={() => this.setState({ modalOpen: false })}
      >
        <ModalHeader>
          notification
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col className="text-center">
                A great profile helps you stand out from the crowd and increases
                your chances of getting your perfect home.
              </Col>
            </Row>
            <Row style={{ marginTop: '40px' }}>
              <button
                className="w-100 m-auto btn btn-primary text-bold mt-5"
                onClick={() => this.setState({ modalOpen: false })}>
                fill in profile
              </button>
            </Row>
            <p style={{ textAlign: 'center', marginTop: '20px' }}>
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => this.setState({ modalOpen: false })}>Skip For Now</span>
            </p>
          </Container>
        </ModalBody>
      </Modal>
    );
  };

  cancelBackStep = () => {
    if (this.props?.location?.state?.flow === 'forgotPassword') {
      this.props.history.push("/forgotpassword")
    } else {
      this.props.history.push("/login")
    }
  }


  handleConfirm = async (e) => {
    e.preventDefault();
    this.setState({disableBtn : true})
    //this.setState({ modalOpen: true })
    if (validate(VerificationSchema)) {
      try {
        let data = {
          activation_code: this.state.activation_code,
        };

        if (this.props?.location?.state?.flow === 'authentication') {
          data.user_id = this.props?.location?.state?.id
        } else {
          data.user_id = this.props?.auth?.userId || this.props?.auth?.id
        }

        let url = "/account-activation";

        const location_state = this.props?.location?.state;
        if (location_state.flow === 'forgotPassword') {
          url = "/confirm-forgot-password-pin";
          data = {
            pin: this.state.activation_code,
            type: location_state.type === "email" ? 2 : 1,
          }
          if (location_state.type === "email") {
            data.email = location_state.data
          } else {
            data.phone_number = location_state.data
          }
        }
        try {
          await postAPI(url, data).then(res => {
            if (res.status === 200) {
              this.setState({disableBtn : false})
              log.info("res success", res);
              Cookies.set("access_token", res.data.data.token, {
                expires: 1,
              });
              this.setState({role: res.data.data.role})
              Cookies.set("role", res.data.data.role, {
                expires: 1,
              });

              if (location_state.flow === 'forgotPassword') {
                this.props.dispatch(setForgotPasswordPin(this.state.activation_code));
                location_state.pin = this.state.activation_code;

                this.props.history.push('/reset-password', location_state)
                toaster('success', res.data.message)
              } else {
                this.props.dispatch(setLoggedIn(res.data.data));
                this.setState({ activated: true });
              }
            } else if (res.response) {
              this.setState({disableBtn : false})
              log.info("res error", res);
              if (res.response.status === 422) {
                if (res.response.data.errors.pin) {
                  toaster('error', res.response.data.errors.pin[0]);
                }
              } else if (res.response.status === 400) {
                toaster('error', res.response.data.message);
              }
            }
          })


        } catch (err) {
          log.info("here", err.response.data)
          toaster('error', err.response.data.message)
          if (this.props.auth.forgotPasswordFlowActive) {
            alert(err.response.data.message);
          }
        }
        // history.push("/profile");
      } catch (err) {
        log.info("there", err);
        toaster('error', err.response.data.message)
        // setInvalidAuth(err.response.data.message);
      }
    }

  };

  render() {
    const auth = this.props.auth;
    const { disableBtn } = this.state;
    const importToolShouldRedirect = Cookies.get('should_redirect_to_import');
    const importToolId = Cookies.get('import_id');

    if (this.state.activated && !this.props.auth.forgotPasswordFlowActive) {
      if(importToolShouldRedirect === 1 && importToolId != 0) {
        let newUrl = '/import/confirm/' + importToolId;

        return <Redirect push to="{newUrl}" />;
      } else if(this.state.role === 3){
        return <Redirect push to="/search-listing" />; //Change the redirect path if required.
      }else if(this.state.role === 2 ){
        return <Redirect push to="/dashboard" />;
      }
    }

    if (this.state.activated && this.props.auth.forgotPasswordFlowActive) {
      return <Redirect push to="/forgetchangepassword" />;
    }

    return (
      <AuthLayout title={this.props?.location?.state?.flow === "signupflow" ? "verify your email" : "enter four digit code"} image="verify-your-email.svg" history={this.props.history}>
        <p className="authentication-page-form-links mb-4">
          A 4 digit PIN has been sent to {auth.email ? auth.email : (this.props?.location?.state ? this.props?.location?.state?.data : 'xyz******@gmail.com')}.<br />
          Please enter it here to confirm your account.
        </p>
        <Form id="Form" className="auth-verification">
          {this.renderInput(
            "number",
            "security_pin",
            "Enter 4 Digit PIN here",
            this.onChangeActivationCode
          )}
          {/* {this.props.location.state && this.props.location.state.flow !== 'forgotPassword' &&
            <p className="authentication-page-change-email">
              Change Email Address
          </p>
          }*/}
          <Button
            type='submit'
            className="btn btn-primary text-bold mt-5"
            id="BTN-verification"
            data-description="Visitor verified account"
            onClick={this.handleConfirm}
            style={{ width: '100%' }}
            disabled={disableBtn}
          >
            Confirm
          </Button>
          <Button className="btn text-bold btn-default2"
            style={{ width: '100%' }} onClick={() => this.cancelBackStep()}>Cancel</Button>
        </Form>
        {this.renderModal()}
      </AuthLayout>
    );
  }
}

const select = (state) => ({
  auth: state.auth,
});
export default connect(select)(Verification);
