import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { logger, toaster } from '../../../Utils';
import { LargeButton, SidePanel, PhoneNumberFormat } from '../../components';
import { withRouter } from 'react-router-dom';
import './style.scss'
import { getAPI, postAPI } from '../../../Api';
import { validate } from '../../../Utils/Validation/validation';
import { useDispatch } from 'react-redux';
import { getBackPath } from '../../../Store/actions/Action';
import { getQueryParams, postNotes} from "./helper";

const log = logger('MarkAsCompleted');
const markCompleteSchema = {
    message_to_landlord: [
        { rule: "required", message: "Message to landlord required" },
    ],
    notes_internal: [
        { rule: "required", message: "Internal notes required" },
    ]
}
const MarkAsCompleted = (props) => {
    const { isShow, handleClose, setOpenModal, openAssignedRequest, clickedData, markeData, setTableData, filterData, setTabledata, setIsLoading, onSaveInternalNotes } = props;
    const [notes_internal, setNotes] = useState("");
    const [message_to_landlord, setMessage] = useState("");
    const [viewData, setViewData] = useState(null);
    // const currentMessage = useSelector(state => state.requestReducer.tableData.data)
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setNotes(e.target.value);
    }
    const handleCloseConfirm = () => {
        handleClose();
        setMessage('');
        setNotes('');
        
		const params = getQueryParams(window.location.search, ['request_type_id', 'id']).split(",");
        if (parseInt(params[0]) && parseInt(params[1])) {
            props.history.push(props.location.pathname);
        }
    }
    const markAsCompleted = () => {

        const data = {
            "request_type_id": viewData.request_type_id,
            "id": viewData.id,
            "internal_notes": notes_internal
        }
        if (viewData.request_type_id === '8') {
            data.message_to_landlord = message_to_landlord
        }
        if (validate(markCompleteSchema)) {
            markeData(data);
            handleClose();
            setOpenModal(true)
        }

    }
    const openPhotoShoot = () => {
        props.history.push({ pathname: `/profile-detail/listings/${viewData.role.toLowerCase()}/${viewData.landlord_id}/${viewData.property_id}/${viewData.property_detail_id}`, state: { path: 'request-management' } })
    }
    const assignedRequest = () => {
        handleClose();
        openAssignedRequest(true)
    }
    const cancelAssignedRequest = () => {
        getAPI(`/admin/requests/cancel-assignee/${viewData.request_type_id}/${viewData.id}`).then(response => {
            if (response.status === 200) {
                let url = `/admin/requests/view/${viewData.request_type_id}/${viewData.id}`;
                getAPI(url).then(res => {
                    if (res.status === 200) {
                        setViewData(res.data.data)
                        if (res.data.data) {
                            setNotes(res.data.data.internal_notes ? res.data.data.internal_notes : '')
                            setMessage(res.data.data.message_to_landlord !== null ? res.data.data.message_to_landlord : '');
                        }
                    }
                })
            }
        })
    }
    const viewListingDetails = () => {
        dispatch(getBackPath('request'))
        props.history.push({ pathname: `/profile-detail/listings/${clickedData.role.toLowerCase()}/${viewData.landlord_id}/${viewData.property_id}/${viewData.property_detail_id}`, state: { path: 'request-management' } })
    }
    useEffect(() => {
        setNotes('');
        setMessage('');
        if (clickedData !== null) {
            let url = `/admin/requests/view/${clickedData.request_type_id}/${clickedData.id}`;
            getAPI(url).then(response => {
                setViewData(response.data.data)
                if (response.data.data) {
                    setNotes(response.data.data.internal_notes ? response.data.data.internal_notes : '')
                    setMessage(response.data.data.message_to_landlord !== null ? response.data.data.message_to_landlord : '');
                }
            })
        }
    }, [clickedData])

    const saveInternalNotes = async () => {
        log.info("viewData", viewData)
        if(viewData && viewData.internal_notes !== notes_internal){
            
            const data = {
                "request_type_id": viewData.request_type_id,
                "id": viewData.id,
                "internal_notes": notes_internal || '',
                "message_to_landlord" : ''
            }

            const res = await postNotes(data);
                if (res.status === 200) {
                    toaster('success', 'Note Saved Successfully!')
                    log.info("Save Notes Response", res);
                    onSaveInternalNotes();
                }
        }
		
    }

    let request_type_id = null;
    if (viewData !== null) {
        // let type = typeof viewData.request_type_id;
        request_type_id = Number(viewData.request_type_id)
        // { log.info('request_type_id==>', typeof request_type_id) }
    }

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleCloseConfirm()}
            title='Request Details'>
            {viewData !== null &&
                <>
                    <div className='sidepanel-body mark_completed'>
                        {request_type_id === 1 && <>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>referrer's name</Form.Label>
                                    <span>{viewData.name ? viewData.name : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>request type</Form.Label>
                                    <span>{viewData.request_type ? viewData.request_type : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6' style={{ wordBreak: 'break-word' }}>
                                    <Form.Label>referrer's email</Form.Label>
                                    <span>{viewData.email ? viewData.email : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>referrer's contact no.</Form.Label>
                                    <span>{viewData.contact_number ? <PhoneNumberFormat phoneNumber={viewData.contact_number} /> : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>request date</Form.Label>
                                    <span>{viewData.request_date ? viewData.request_date : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>Role</Form.Label>
                                    <span>{viewData.role ? viewData.role : '--'}</span>
                                </Form.Group>
                            </Row>
                            <hr />
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>referred's name</Form.Label>
                                    <span>{(viewData.owner_first_name && viewData.owner_last_name) ? `${viewData.owner_first_name} ${viewData.owner_last_name}` : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6' style={{ wordBreak: 'break-word' }}>
                                    <Form.Label>referred's email</Form.Label>
                                    <span>{viewData.owner_email ? viewData.owner_email : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>referred's contact no.</Form.Label>
                                    <span>{viewData.owner_phone_number ? <PhoneNumberFormat phoneNumber={viewData.owner_phone_number} /> : '--'}</span>
                                </Form.Group>
                            </Row>
                        </>}
                        {/* 1: Realtor Partner,2: Property Management,3: Advertising,4: Panda Partner,8.Listing Promotion  */}
                        {(request_type_id === 2 || request_type_id === 3 || request_type_id === 4 || request_type_id === 8 || request_type_id === 11) && <>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>name</Form.Label>
                                    <span>{viewData.name ? viewData.name : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>request type</Form.Label>
                                    <span>{viewData.request_type ? viewData.request_type : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6' style={{ wordBreak: 'break-word' }}>
                                    <Form.Label>email</Form.Label>
                                    <span>{viewData.email ? viewData.email : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>contact number</Form.Label>
                                    <span>{viewData.contact_number ? <PhoneNumberFormat phoneNumber={viewData.contact_number} /> : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>request date</Form.Label>
                                    <span>{viewData.request_date ? viewData.request_date : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>Role</Form.Label>
                                    <span>{viewData.role ? viewData.role : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                 
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>City</Form.Label>
                                    <span>{viewData.city ? viewData.city : '--'}</span>
                                </Form.Group>
                            </Row>
                            {request_type_id === 8 && <Row className='row-spacing btn-update'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>promotion plan</Form.Label>
                                    <span>{viewData.promotion_plan ? viewData.promotion_plan : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>listing request</Form.Label>
                                    <LargeButton title='view listing' onClick={() => viewListingDetails()} variant='dark' style={{ width: '119px' }} />
                                </Form.Group>
                            </Row>}
                            {request_type_id === 8 && <Row className='row-spacing'>

                                {(viewData.promotion_items && viewData.promotion_items.length !== 0) &&
                                    viewData.promotion_items.map((data, i) => {
                                        return <React.Fragment key={i}>
                                            <Form.Group as={Col} md='6'>
                                                <Form.Label>{data}</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} md='6'>
                                                {log.info(data)}
                                                {data === 'Kjiji Posting' ? <a href='https://www.kijiji.ca/' target="_blank">Post</a> : <Form.Label className='non-post'>N/A</Form.Label>}
                                            </Form.Group>
                                        </React.Fragment>
                                    })

                                }
                            </Row>}
                        </>}

                        {/* 12: Tenant Aquisition */}
                        {(request_type_id === 12 || request_type_id === 13) && <>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>Requested By</Form.Label>
                                    <span>{viewData.requested_by ? viewData.requested_by : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>contact</Form.Label>
                                    <span>{viewData.contact_number ? <PhoneNumberFormat phoneNumber={viewData.contact_number} /> : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>day and time</Form.Label>
                                    {(viewData.day && viewData.time) ? <span>{viewData.day} ({viewData.time})</span> : '--'}
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>email</Form.Label>
                                    <span>{viewData.email ? viewData.email : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='5'>
                                    <Form.Label>city</Form.Label>
                                    <span>{viewData.city ? viewData.city : '--'}</span>
                                </Form.Group> 
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>request type</Form.Label>
                                    <span>{viewData.request_type ? viewData.request_type : '--'}</span>
                                </Form.Group> 
                            </Row>}
                        </>}

                        {/* 5: Professional Photoshoot,6: Paralegal Consulting, */}
                        {(request_type_id === 5 || request_type_id === 6 || request_type_id === 9 || request_type_id === 10) && <>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='7'>
                                    <Form.Label>Requested By</Form.Label>
                                    <span>{viewData.requested_by ? viewData.requested_by : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='5'>
                                    <Form.Label>role</Form.Label>
                                    <span>{viewData.role ? viewData.role : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='7'>
                                    <Form.Label>date and time</Form.Label>
                                    {(viewData.date && viewData.time) ? <span>{viewData.date} <span style={{ textTransform: 'capitalize' }}>{`(${viewData.time})`}</span></span> : '--'}
                                </Form.Group>
                                <Form.Group as={Col} md='5'>
                                    <Form.Label>city</Form.Label>
                                    <span>{viewData.city ? viewData.city : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='7'>
                                    <Form.Label>request type</Form.Label>
                                    <span>{viewData.request_type ? viewData.request_type : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='5'>
                                    <Form.Label>contact number</Form.Label>
                                    <span>{viewData.contact_number ? <PhoneNumberFormat phoneNumber={viewData.contact_number} /> : '--'}</span>
                                </Form.Group>
                            </Row>
                            {(viewData.request_type_id === '5' || viewData.request_type_id === '9' || parseInt(viewData.request_type_id) === 10) && <Row className='row-spacing'>
                                <Form.Group as={Col} md='12'>
                                    <Form.Label>Address</Form.Label>
                                    <span>{viewData.address ? viewData.address : '--'}</span>
                                </Form.Group>
                            </Row>}
                            {viewData.request_type_id === '6' && <Row className='row-spacing'>
                                <Form.Group as={Col} md='12'>
                                    <Form.Label>question</Form.Label>
                                    <span>{viewData.question ? viewData.question : '--'}</span>
                                </Form.Group>
                            </Row>}
                        </>}

                        {/* 7: Full Service Inquiry,  */}
                        {request_type_id === 7 && <>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>requested by</Form.Label>
                                    <span>{viewData.requested_by ? viewData.requested_by : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>contact</Form.Label>
                                    <span>{viewData.contact_number ? <PhoneNumberFormat phoneNumber={viewData.contact_number} /> : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>day and time</Form.Label>
                                    {(viewData.day && viewData.time) ? <span>{viewData.day} ({viewData.time})</span> : '--'}
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>email</Form.Label>
                                    <span>{viewData.email ? viewData.email : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>request type</Form.Label>
                                    <span>{viewData.request_type ? viewData.request_type : '--'}</span>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <Form.Label>role</Form.Label>
                                    <span>{viewData.role ? viewData.role : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='12'>
                                    <Form.Label>property address</Form.Label>
                                    <span>{viewData.property_address ? viewData.property_address : '--'}</span>
                                </Form.Group>
                            </Row>
                            <Row className='row-spacing'>
                                <Form.Group as={Col} md='12'>
                                    <Form.Label>vacanted</Form.Label>
                                    <span>{viewData.vacanted ? viewData.vacanted : '--'}</span>
                                </Form.Group>
                            </Row>
                        </>}

                        <hr />
                        <h4 className='sub-heading'>Actions</h4>
                        <Form id={'Form'}>
                            <Row>
                                {/* {request_type_id === 8 &&
                                    <Form.Group as={Col} md='12' className='message-border pb-4'>
                                        <Form.Label className='custom-lable'>Internal Notes</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            rows={5}
                                            name="message_to_landlord"
                                            id="message_to_landlord"
                                            value={message_to_landlord}
                                            placeholder="Write here..."
                                            onChange={(e) => setMessage(e.target.value)}
                                            disabled={viewData.request_status === 1 && true}
                                        />
                                    </Form.Group>
                                } */}
                                <Form.Group as={Col} md='12'>
                                    <Form.Label className='custom-lable'>Internal Notes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={5}
                                        name="notes_internal"
                                        id="notes_internal"
                                        value={notes_internal}
                                        placeholder="Write here..."
                                        onChange={(e) => handleChange(e)}
                                        disabled={viewData.request_status === 1 && true}
                                    />
                                    <LargeButton  title="Save" variant='dark' onClick={() => saveInternalNotes()} />
                                </Form.Group>
                            </Row>
                        </Form>

                        {viewData.assigned_to !== null ?
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='assigned-to m-0'><span className='opacity-extra'>Assigned To: </span> <span className='pl-2'>{viewData.assigned_name}</span></p>
                                {viewData.request_status === 0 && <button type='button' className='cancel-btn m-0' onClick={() => cancelAssignedRequest()}>cancel</button>}
                            </div> :
                            <div>
                                {viewData.request_status === 0 && <button type='button' className='cancel-btn pull-right m-0' onClick={() => assignedRequest()}>+ Assign Request</button>}
                            </div>
                        }
                        {(request_type_id === 5 && props.path === 'request') && <Row className='mt-5 mb-5 ml-3 mr-3'>
                            <LargeButton title={viewData.request_status === 0 ? 'upload photos' : 'view and edit photos'} variant='dark' onClick={() => openPhotoShoot()} />
                        </Row>}

                    </div>

                    <Row>
                        {log.info(viewData.request_status === 1)}
                        <Col md={12} className='fixed-bottom bottom-btn mark-completed'>
                            <LargeButton saveLoading={false} title={viewData.request_status === 0 ? 'mark as completed' : 'completed'} isDisable={viewData.request_status === 1 ? true : false} onClick={() => markAsCompleted()} style={{ width: '184px', float: 'right' }} />
                        </Col>
                    </Row>
                </>
            }
        </SidePanel>
    </>);
}
export default withRouter(MarkAsCompleted);