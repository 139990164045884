// let BASE_URL = 'https://staging-api.rentpanda.ca';
// let BASE_URL = 'https://dev-api.rentpanda.ca';
let BASE_URL = process.env.REACT_APP_BASE_API_URL;

// API URL
export const API_URL = `${BASE_URL}/api`;

// API URL
export const FILE_API_URL = `${API_URL}/file`;

//Image Upload API URL
export const IMAGE_API_URL = `${API_URL}/image`;

//Upload signed lease api url 
export const UPLOAD_SIGNED_LEASE_API = `${API_URL}/landlord/listing/lease/upload-signed-lease`;

//Socket URL
export const SOCKET_URL = `${BASE_URL}:3000`

//GoogleMapAPI
export const Google_Map_API = process.env.REACT_APP_GOOGLE_MAP_API;

// GoogleCaptchaApiKey
export const Google_Captcha_API = process.env.REACT_APP_GOOGLE_CAPTCHA_API;