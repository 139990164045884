import React from 'react';
import { useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap';
import { DollorNumberDisplay, ProfileCard } from '../../../components';
import { pet_typeData } from '../../../../Constants/tenantDetails';
import Cookies from 'js-cookie';
const PetsInfoDisplay = (props) => {
  const { editaction } = props;
  const role = Number(Cookies.get('role'));
  const petInfo = useSelector(state => state.UserProfileReducer.userProfile.pets_info) || props?.profileDetails?.pets_info
 
  return (
    <ProfileCard
      isComplete={petInfo?.pet_status !==null}
      title={role !== 1 ?<> Pet Info   </> : 'Pet Information'}
      editaction={editaction}>

      <Container fluid>
        { petInfo && petInfo.pet_status === null ? 
        <Row>
          <Col md='12' className='d-flex justify-content-center'>
            <label>No Information Provided</label>
          </Col>
        </Row> :
        petInfo && petInfo['pet_info'] && petInfo.pet_status === 1 ?
          <Row>
            <Col md='2'>
              <label>Pet Type</label>
              <h3>{petInfo['pet_info'] && pet_typeData.filter((data) => {
                let title = '';
                if (petInfo['pet_info'].pet_type.includes(data.value)) {
                  title = data.title;
                }
                return title;
              }).map(function (obj) {
                return obj.title;
              }).join(', ')}
              </h3>
            </Col>
            <Col md='1'>
              <label>Amount</label>
              <h3>{petInfo['pet_info'].pet_count}</h3>
            </Col>
            <Col md='5'>
              <label>Additional Pet Info</label>
              <h3>{petInfo['pet_info'].pet_description ? petInfo['pet_info'].pet_description : '--' }</h3>
            </Col>
            {petInfo['pet_info'].pet_deposit_amount ?
              <Col md='2'>
                <label>Pet Deposit</label>
                <h3><DollorNumberDisplay price={petInfo['pet_info'].pet_deposit_amount} /></h3>
              </Col> : ''}
          </Row> :
          <Row>
            <Col md='12' className='d-flex justify-content-center'>
              <label>{role === 3 ? 'You have' : 'Tenant has'} no pets</label>
            </Col>
          </Row>}
      </Container>
    </ProfileCard>
  );
}

export default PetsInfoDisplay;