import React, { useState } from "react";
import { Button, Image, Row, Col } from "react-bootstrap";
import { ModalBox } from "../../../components";
import { getCurrentPage } from "../../../../Store/actions/PropertyDetailAction";
import { useSelector, useDispatch } from "react-redux";
import { logger } from "../../../../Utils";

const log = logger("StepFive");
const StepFive = (props) => {
  const{ setPhotoType } = props

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  log.info("propertyDetails", propertyDetails.city);

    return (<>
      <div className="inner-wrapper cu-step-form-body" style={{minHeight:"500px"}}>
        <div className="body-container">
          <div className="body-header">
              <h3>it’s time for the pictures</h3>
          </div>
          <Row>
            <Col data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" md="6">
            {propertyDetails.city === 'Thunder Bay' ? 
              <div data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" className='image-listing-wrapper' onClick={() => setModalShow(true)}>
                <div data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" className='image-listing-inner'>
                  <Image data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" className="icon" src={require("../../../../_assets/images/photo-icon.png")} alt="add"/>
                  <h4 data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init">Upload Photos</h4>
                  <p data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init">Use photos you have or take them on your phone right now!</p>
                </div>
                <Image data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" src={require("../../../../_assets/images/path.svg")} alt="path"/>
              </div> :
              <div data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" className='image-listing-wrapper' onClick={() => dispatch(getCurrentPage(5))}>
                <div data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" className='image-listing-inner'>
                  <Image data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" className="icon" src={require("../../../../_assets/images/photo-icon.png")} alt="add"/>
                  <h4 data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init">Upload Photos</h4>
                   <p data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init">Use photos you have or take them on your phone right now!</p>
                </div>
                <Image data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" src={require("../../../../_assets/images/path.svg")} alt="path"/>
              </div>}
            </Col>
            <Col data-description="Landlord initiated request for pro photography" data-gtmtrigger="BTN-listing-step-5-photo-pro-init" md="6">
              {propertyDetails.city === 'Thunder Bay' && 
                    <div data-description="Landlord initiated request for pro photography" data-gtmtrigger="BTN-listing-step-5-photo-pro-init" className='image-listing-wrapper' id="pro-photo-container" onClick={() => setPhotoType('professional')}>
                    <div data-description="Landlord initiated request for pro photography" data-gtmtrigger="BTN-listing-step-5-photo-pro-init" className='image-listing-inner'>
                      <Image data-description="Landlord initiated request for pro photography" data-gtmtrigger="BTN-listing-step-5-photo-pro-init" className="icon" src={require("../../../../_assets/images/camera-icon.png")} alt="add"/>
                      <h4 data-description="Landlord initiated request for pro photography" data-gtmtrigger="BTN-listing-step-5-photo-pro-init">Get Professional Photos, Free!</h4>
                      <p data-description="Landlord initiated request for pro photography" data-gtmtrigger="BTN-listing-step-5-photo-pro-init">We’ll do the photos for you and you’ll get your listing verified at the same time!</p>
                    </div>
                    <Image data-description="Landlord initiated request for pro photography" data-gtmtrigger="BTN-listing-step-5-photo-pro-init" src={require("../../../../_assets/images/path.svg")} alt="path"/>
                  </div>
              }
            </Col>
          </Row>
        </div>
      </div>

      <div className="container-footer cu-step-form-footer">
        <Button variant="secondary" onClick={()=> dispatch(getCurrentPage(3))}>Back</Button>
        <Button variant="success" onClick={()=> dispatch(getCurrentPage(5))}>Next</Button>
      </div>

      <ModalBox
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        btnelementid={'BTN-listing-step-5-request-verification'}
        btndatadescription={'Landlord chose get verified now'}
        actionbuttontitle={'get verified now'}
        buttonaction={() => {
          setModalShow(false)
          setPhotoType('professional')
        }}
        lnkelementid={'BTN-listing-step-5-defer-verification'}
        lnkdatadescription={'Landlord chose get verified later'}
        backbuttontitle={'get verified later'}
        backaction={() =>{
          setModalShow(false)
          dispatch(getCurrentPage(5))
        }}
      >
        <h4>want to get your property <br/>verified?</h4>
        <p>by uploading your own photos, your listing won't receive the "verified" badge.<br/>
         getting your property verified is free, takes less than 30 mins and involves our team coming by to take professional photos.</p>
      </ModalBox>
   </> );
}

export default StepFive;
