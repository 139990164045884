import React, { useState } from "react";
import { SidePanel, ModalBox, DatePickerComponent } from "../../../components"
import { Form, Row, Col, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FreeRentAssessmentValidationScheme } from './FreeRentAssessmentValidationScheme'
import { validate } from '../../../../Utils/Validation/validation';
import moment from 'moment';
import { postAPI } from "../../../../Api";
import { toaster } from "../../../../Utils";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { Loader, AutoCompleteAddress } from '../../../../App/components';

const FreeRentAssessment = (props) => {
  const { isShow, handleClose, setFree_rent_assessment_id ,requestType} = props
  const [date, setDate] = useState(new Date());
  const [assessment_time, setAssessment_time] = useState('select');
  const [phone_number, setPhone_number] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [fullName, setFullName] = useState('');
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [email, setEmail] = useState('');

  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  
  const [inputList, setInputList] = useState([{ address: ""}]);
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { address: "" }]);
  };

  const OnChangeInput = (event) => {
    if (event.target.name === 'assessment_time') {
      setAssessment_time(event.target.value)
    } else if (event.target.name === 'phone_number_free') {
      setPhone_number(event.target.value)
    }
  }
  const clearForm = () => {
    setFullName('');
    setDate(new Date());
    setAssessment_time('select');
    setPhone_number('');
  }
  
  const handlePlaceChanged = (place, index) => {
        const address = place.formatted_address;
    
        //const { name, value } = e.target;
        console.log('address index' + index);
        
        const list = [...inputList];
        list[index]['address'] = address;
        setInputList(list);
  }
  
  const handleChange = event => {
      event.target.setAttribute('autocomplete', 'off');
      //setAddress(event.target.value);
      console.log(event.target);
  }

  const onSubmit = (e) => {
    e.preventDefault()
    console.log(validate(FreeRentAssessmentValidationScheme))
    if(validate(FreeRentAssessmentValidationScheme)){
      const url = '/landlord/create-free-rent-assessment';
      const data = {
        property_id: property_id,
        assessment_date: moment(date).format('YYYY-MM-DD'),
        assessment_time: assessment_time,
        phone_number: phone_number.replaceAll("-",''),
        request_type:requestType || 10,
        addresses: false,
      }
      
      if (inputList.length > 0) {
        data.addresses = inputList;
      }

      if (property_details_id) {
        data.property_details_id = property_details_id;
      }
      if (props.landlordLanding) {
        data.full_name = fullName;
      }
      postAPI(url, data).then(response => {
        if (response === undefined) {
          toaster('error', 'Something went wrong!');
        } else if (response.status === 200) {
          setFree_rent_assessment_id(response.data.data.free_rent_assessment_id)
          handleClose()
          setModalShow(true)
          clearForm()
        } else if (response.response) {
          if (response.response.status === 422) {
            if (props.landlordLanding) {
              if (response.response.data.errors.property_id) {
                setErrorModalText('You are already a member');
              } else {
                setErrorModalText(response.response.data.message);
              }
              setErrorModalShow(true);
            } else {
              toaster('error', response.response.data.message);
            }
          }
        }
      })
    }
  }
  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={`${props.landlordLanding ? "Let's chat about your rental needs." : "Get A Free Rent Assessment"}`}
      subTitle={`${props.landlordLanding ? "Fill out the info below and we will have a pro give you a call." : "Let us know what time is best to reach you and we will call to book you in."}`}>
      <Form id="Form" autocomplete="off">
        {props.landlordLanding && <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Your full name</Form.Label>
            <Form.Control
              type='text'
              placeholder='John Doe'
              name="fullName"
              id="fullName"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
            />
          </Form.Group>
        </Row>}
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>What date can our city manager call you?</Form.Label>
            <DatePickerComponent
              name="date"
              id="datefreeass"
              value={date}
              handleDateChange={date => setDate(date)}
              displayText={"Select Date"}
              minDate={new Date()}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>When would be best to contact you?</Form.Label>
            <Form.Control
              as="select"
              className="my-1 mr-sm-2"
              id="free-inlineForm"
              custom
              name="assessment_time"
              value={assessment_time}
              onChange={(e) => OnChangeInput(e)}
            >
              <option value="select">Select one</option>
              <option value="morning">Morning</option>
              <option value="afternoon">Afternoon</option>
              <option value="evening">Evening</option>
            </Form.Control>
          </Form.Group>
        </Row>
        {props.tabsSection && <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Your email address?</Form.Label>
            <Form.Control
              type='email'
              placeholder='Email'
              name="email"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>
       </Row>}
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Phone Number</Form.Label>
            <NumberFormat
              format="###-###-####"
              mask="_"
              placeholder="123-123-1234"
              name="phone_number_free"
              id="phone_number_free"
              className="form-control"
              value={phone_number ? phone_number : ""}
              onChange={(e) => OnChangeInput(e)}
            />
          </Form.Group>
        </Row>
        {props.tabsSection && <Row>
          <Form.Group as={Col} md="12">
          <Form.Label>Address of your rental property?</Form.Label>
          {inputList.map((x, i) => {
            return (
              <Form.Group>
                <AutoCompleteAddress
                    className="searchBar"
                    placeholder="Type to search location"
                    name="address"
                    selectPlaceOnEnter
                    updateField={(data) => handleChange(data)}
                    handlePlaceChanged={(data) => handlePlaceChanged(data, i)}
                />
                <div className="property-btn">
                  {inputList.length !== 1 && <button
                    className="remove-btn"
                    onClick={() => handleRemoveClick(i)}><b>-</b> Remove</button>}
                  {inputList.length - 1 === i && <button 
                    className="add-btn" onClick={handleAddClick}><b>+</b> Add property</button>}
                </div>
              </Form.Group>
            );
          })}
          </Form.Group>
          {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
        </Row>}
      </Form>

      <div className="container-footer">
        <Button variant="success" onClick={(e) => onSubmit(e)} id="BTN-landlord-lets-chat-submit" data-description="Landlord submitted request to chat">Submit</Button>
      </div>
    </SidePanel>

    <ModalBox
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="sm"
      actionbuttontitle={'got it'}
      buttonaction={() => setModalShow(false)}
    >
      <p>Your inquiry has been received. We will give you a call at the time selected.</p>
    </ModalBox>

    <ModalBox
      show={errorModalShow}
      onHide={() => setErrorModalShow(false)}
      size="sm"
      actionbuttontitle={'got it'}
      buttonaction={() => setErrorModalShow(false)}
    >
      <p>{errorModalText}</p>
    </ModalBox>
  </>);
}

export default FreeRentAssessment;