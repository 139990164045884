import React from 'react'
import HorizontalPropertyCard from '../../components/PropertyCard/HorizontalPropertyCard';
import {formattedDetails} from './helper'

function MobileListView(props) {
 
    const {horizontalRef, listData, propertyDetails,selectedId } = props
 
    return (
        <div className={'horizontal_card_container'} style={{ top: selectedId ? '74%' : '60%' }} ref={horizontalRef} id='horizontal_card_container'>
            {listData && listData.data?.map((data) =>
                <div className={'horizontal_card_item'} id={`card_item_${data.property_details_id}`} >
                    <HorizontalPropertyCard
                        price={data.price}
                        id={data.property_details_id}
                        isVerifed={data.is_verified}
                        image_url={data.image_url}
                        title={data.address}
                        details={formattedDetails(data)}
                        isDeleteIcon={data.isDeleteIcon}
                        grid={12}
                        onClick={() => propertyDetails(data.id, data.property_details_id)}
                        isLoveIcon={data.is_favorite}
                        selectedId={selectedId}
                        {...data}
                    />

                </div>)}
        </div>
    )
}

export default MobileListView
