export const SET_SHOWING_CALENDAR_DATA = 'SET_SHOWING_CALENDAR_DATA';
export const SET_SHOWING_LIST_DATA = 'SET_SHOWING_LIST_DATA';
export const SET_SHOWING = 'SET_SHOWING';

export function setShowingCalendarData(data) {
    return function (dispatch) {
        dispatch({ type: SET_SHOWING_CALENDAR_DATA, showingCalendarData: data });
    };
}

export function setShowingListData(data) {
    return function (dispatch) {
        dispatch({ type: SET_SHOWING_LIST_DATA, showingListData: data });
    };
}
export function setShowing(data) {
    return function (dispatch) {
        dispatch({ type: SET_SHOWING, payload: data });
    };
}