import React, { useEffect, useState } from 'react';
import { Row, Col, Nav, Image } from 'react-bootstrap';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import './style.scss';
import { getAPI, postAPI } from '../../../Api';
import { toaster } from '../../../Utils';
import { AppLayout, PageTitleHeader, LargeButton, ModalBox } from '../../components';
import AdminUserDetails from '../../components/AdminUserDetils';
import Listing from './ProfileComponent/Listing';
import Leases from './ProfileComponent/Leases';
import Notices from './ProfileComponent/Notices';
import Maintainance from './ProfileComponent/Maintainance';
import Inspection from './ProfileComponent/Inspection';
import AdminResetPassword from "./AdminResetPassword";
import Showings from './ProfileComponent/Showings';
import AdminShowings from './ProfileComponent/AdminShowings';
import UserDetailContentLoader from '../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/UserDetailContentLoader/UserDetailContentLoader';
import { useSelector } from 'react-redux';
import { clearRawData } from '../../../Store/actions/RawDataAction';
import { resetPassword } from './helper';

const CustomLink = ({ to, className, style, active, children }) => {
	const history = useHistory()
	return (
		<NavLink
			style={{ ...style }}
			to={to}
			onClick={() => {
				clearRawData()
				setTimeout(() => history.push(to), 300)
			}}
			className={className}
			activeclassname={active}
		>{children}
		</NavLink>
	)
}

const AdminLandlordProfile = (props) => {
	const [user_id, setUserId] = useState('');
	const [user_type, setUser_type] = useState('');
	const [profileDetails, setProfileDetails] = useState([])
	const [openSwitchModal, setOpenSwitchModal] = useState(false);
	const [profile, setProfile] = useState(null);
	const [action, setAction] = useState('');
	const [buttonTitle, setButtonTitle] = useState('');
	const [is_showingRequest, setShowingRequest] = useState(false);
	const [showingDetails, setShowingDetails] = useState(false);
	const [showingRequestdata, setShowigRequestData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [resetPasswordModalShow, setResetPasswordModalShow] = useState(false);
	const backPath = useSelector(state => state.propertyDetailsReducer.backPath);
	
	const [componentLoad, setComponentLoad] = useState('profile');
	useEffect(() => {
		const slug = props.match.params.slug;
		setComponentLoad(slug)
		// setPath(props.location.state.path)
	}, [props.match.params.slug]);

	useEffect(() => {
		if (props.match.params.user_id) {
			const userType = props.match.params.user_type;
			setUserId(Number(props.match.params.user_id));
			setUser_type(userType);
 			if (userType === 'landlord') {
				if (props.match.params.user_id !== undefined || props.match.params.user_id !== '' || props.match.params.user_id !== null) {
					const url = '/admin/users/landlord/listing/' + props.match.params.user_id;
					
					const dataInside = {
						'filter': {
							'city': '',
							'address': '',
							'landlord': '',
							'tenant': '',
							'property_type': '',
							'status': ''
						},
						'sort_by_price': {
							'order': 'low_to_high'
						},
						'limit': 10
					}
					postAPI(url, dataInside)
						.then(response => {
 							if (response.status === 200) {
								setIsLoading(false)
								const res = response.data.data;
								setProfileDetails(res)
							}
						})
				}
			}
		}
	}, [props.match.params.user_id, props.match.params.user_type])

	
	const back = () => {
		if (backPath === 'active') {
			props.history.push({ pathname: '/dashboard/all-user' });
		} if (backPath === 'inactive') {
			props.history.push({ pathname: '/dashboard/inactive-user' });
		}
	}
	const setOnClickShowings = (userData) => {
		setShowingRequest(true);
		setShowingDetails(userData);
		const urlRequest = '/admin/users/landlord/showing-request/' + userData.id;
		getAPI(urlRequest).then(res => {
			setShowigRequestData(res.data.data)
		})
	}
	const userDetails = user_type && user_type === 'tenant' ? profileDetails.basic_info : profileDetails.user
	const data = {
		'filter': {
			'city': '',
			'address': '',
			'landlord': '',
			'tenant': '',
			'property_type': '',
			'status': ''
		},
		'sort_by_price': {
			'order': 'low_to_high'
		},
		'limit': 10
	}
	const toggleModal = (data, action) => {
		setOpenSwitchModal(!openSwitchModal);
		setAction(action);
		if (action === 'switch') {
			onClickSwitch(data)
			setButtonTitle('yes, switch profile');
		} else if (action === 'suspend' || action === 'active') {
			suspendUser(data);
			if (action === 'suspend') {
				setButtonTitle('yes, suspend');
			} else if (action === 'active') {
				setButtonTitle('yes, activate');
			}
		}
	}
	const onClickSwitch = (data) => {
		setProfile(data);
	}
	const suspendUser = (userData) => {
		setProfile(userData);
	}
	const goBack = () => {
		let url = '';
 		if (action === 'switch') {

			url = '/admin/users/switch-to-tenant/' + profile.id;
			getAPI(url).then(response => {
				toggleModal();
				if (response.status === 200) {
					props.history.push('/profile-detail/tenant/' + profile.id + '/profile');
				} else if (response.response & response.response.status === 400) {
					toaster('error', response.response.data.message)
				}

			})
		} else if (action === 'suspend' || action === 'active') {
 
			if (action === 'suspend') {
				url = '/admin/users/suspend/' + user_id;
			} else if (action === 'active') {
				url = '/admin/users/activate/' + user_id;
			}
			getAPI(url).then(res => {
				const urlBase = '/admin/users/landlord/listing/' + user_id;
				postAPI(urlBase, data)
					.then(response => {
						if (response.status === 200) {
							setIsLoading(false);
							const res = response.data.data;
							setProfileDetails(res)
						}
					})
				setOpenSwitchModal(false);
			})
		}
	}
 
const onResetPasswordSubmit=async(password)=>{
  await resetPassword(user_id,password)
 }
 
	return (
    <>
      <AppLayout>
       {resetPasswordModalShow && <AdminResetPassword
          modalShow={resetPasswordModalShow}
          setModalShow={(val) => setResetPasswordModalShow(val)}
          onSubmit={onResetPasswordSubmit}
        />}

        <div>
          <PageTitleHeader
            isArrow={true}
            onClick={() => back()}
            title={user_type.toUpperCase() + `'S DETAILS`}
          />
        </div>
        {isLoading === true ? (
          <UserDetailContentLoader section={"admin"} />
        ) : (
          <Row className="container-detail admin-section">
            <Col md="12" className="card-detail">
              <div className="detail-left">
                {userDetails && (
                  <AdminUserDetails
                    userDetails={userDetails}
                    user_type={"Landlord"}
                    onResetPassword={()=>{
                    setResetPasswordModalShow(true)         
                    }}
                  />
                )}
              </div>
              <div
                className={`detail-right ${
                  userDetails &&
                  userDetails.property_count === 0 &&
                  userDetails.is_active === "Active"
                    ? "d-flex justify-content-between"
                    : ""
                }`}
              >
                {userDetails &&
                  userDetails.is_active === "Active" &&
                  userDetails.property_count === 0 && (
                    <div
                      className="switch-elm cursorPointer"
                      onClick={() => toggleModal(userDetails, "switch")}
                    >
                      <Image
                        width="20px"
                        height="20px"
                        alt="switch"
                        className="mb-1 mr-2"
                        src={require(`../../../_assets/images/switch-icon.svg`)}
                      />
                      Switch To{" "}
                      {user_type === "landlord" ? "Tenant" : "Landlord"}
                    </div>
                  )}
               

                {userDetails && userDetails.is_active !== "Suspended" && (
                  <LargeButton
                    onClick={() => toggleModal(userDetails, "suspend")}
                    className="suspend-btn"
                    variant={"dark"}
                    title="suspend user"
                    style={{ width: "177px", float: "right" }}
                  />
                )}
                {userDetails && userDetails.is_active !== "Active" && (
                  <LargeButton
                    onClick={() => toggleModal(userDetails, "active")}
                    className="suspend-btn"
                    variant={"dark"}
                    title="activate"
                    style={{ width: "177px", float: "right" }}
                  />
                )}
              </div>
            </Col>
          </Row>
        )}

        <Row className="secoundary-container-detail admin-section">
          <Col md="12" className="secoundary-card-detail">
            <Nav
              fill
              variant="tabs"
              className="user-tab-views tab-space-hover pt-4 pr-4 pl-4"
            >
              {/* <div className='ml-3 mr-3'> */}
              <CustomLink
                style={{ flex: "1 1 auto" }}
                to={{
                  pathname:
                    "/profile-detail/" +
                    user_type +
                    "/" +
                    user_id +
                    "/listings",
                  state: { path: backPath },
                }}
                className="nav-link"
                activeclassname={"active"}
              >
                listings
              </CustomLink>
              <CustomLink
                style={{ flex: "1 1 auto" }}
                to={{
                  pathname:
                    "/profile-detail/" + user_type + "/" + user_id + "/leases",
                  state: { path: backPath },
                }}
                className="nav-link"
                activeclassname="active"
              >
                leases
              </CustomLink>
              <CustomLink
                style={{ flex: "1 1 auto" }}
                to={{
                  pathname:
                    "/profile-detail/" + user_type + "/" + user_id + "/notices",
                  state: { path: backPath },
                }}
                className="nav-link"
                activeclassname="active"
              >
                notices
              </CustomLink>
              <CustomLink
                style={{ flex: "1 1 auto" }}
                to={{
                  pathname:
                    "/profile-detail/" +
                    user_type +
                    "/" +
                    user_id +
                    "/maintainance",
                  state: { path: backPath },
                }}
                className="nav-link"
                activeclassname="active"
              >
                maintainance
              </CustomLink>
              <CustomLink
                style={{ flex: "1 1 auto" }}
                to={{
                  pathname:
                    "/profile-detail/" +
                    user_type +
                    "/" +
                    user_id +
                    "/inspection",
                  state: { path: backPath },
                }}
                className="nav-link"
                activeclassname="active"
              >
                inspection
              </CustomLink>
              <CustomLink
                style={{ flex: "1 1 auto" }}
                to={{
                  pathname:
                    "/profile-detail/" +
                    user_type +
                    "/" +
                    user_id +
                    "/showings",
                  state: { path: backPath },
                }}
                className="nav-link"
                activeclassname="active"
              >
                showings
              </CustomLink>
              {/* </div> */}
            </Nav>
            <div className="table-container admin-view">
              {componentLoad === "listings" && (
                <Listing
                  profileDetails={profileDetails}
                  user_id={user_id}
                  type={"allUsers"}
                />
              )}
              {componentLoad === "leases" && (
                <Leases
                  role="landlord"
                  user_id={user_id}
                  setOnClick={(data) => setOnClickShowings(data)}
                />
              )}
              {componentLoad === "notices" && (
                <Notices role="landlord" user_id={user_id} />
              )}
              {componentLoad === "maintainance" && (
                <Maintainance role="landlord" user_id={user_id} />
              )}
              {componentLoad === "inspection" && (
                <Inspection role="landlord" user_id={user_id} />
              )}
              {componentLoad === "showings" && (
                <Showings
                  role="landlord"
                  user_id={user_id}
                  setOnClick={(data) => setOnClickShowings(data)}
                />
              )}
            </div>
          </Col>
        </Row>
      </AppLayout>

      <ModalBox
        show={openSwitchModal}
        onHide={toggleModal}
        size="sm"
        actionbuttontitle={buttonTitle}
        buttonaction={goBack}
        extraBtnStyle="extraBtnStyle"
      >
        {action === "switch" && (
          <p className="switching-details">
            You are switching{" "}
            <span className="profile-name">{profile && profile.name}’s</span>{" "}
            Profile from a {profile && profile.user_type.toLowerCase()} to a{" "}
            {profile && profile.user_type === "Landlord"
              ? "tenant"
              : "landlord"}{" "}
            Continue?
          </p>
        )}
        {action === "suspend" && (
          <p className="switching-details">
            <span className="mb-2" style={{ display: "block" }}>
              Are you sure to suspend{" "}
              <span className="profile-name">{profile && profile.name}’s?</span>
            </span>
            <span>
              They will be unable to apply to any properties within Rent Panda
            </span>
          </p>
        )}
        {action === "active" && (
          <p className="switching-details">
            <span className="mb-2" style={{ display: "block" }}>
              Are you sure to activate{" "}
              <span className="profile-name">{profile && profile.name}’s?</span>
            </span>
            <span>
              Their rented property will remain within Rent Panda but other
              listing will be activated automatically
            </span>
          </p>
        )}
      </ModalBox>
      <AdminShowings
        isShow={is_showingRequest}
        handleClose={() => setShowingRequest(false)}
        role={1}
        showingDetails={showingDetails}
        requestDetails={showingRequestdata}
      />
    </>
  );
}

export default withRouter(AdminLandlordProfile);
