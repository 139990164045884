import React from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Tab, Nav } from 'react-bootstrap';
import { ToolsInfo } from './';
import { Loader } from '../../../App/components';

import '../style.scss';

const referralTabOneData = [
	{
		id: 1,
		icon: require("../../../_assets/images/icons/realtors-icon-1.svg"),
		title: "property & rent assessment",
		description: "Even before you sign on the dotted line we will do a free inspection of the property and give you a full assessment to figure out rent prices and ideal tenant profiles.",
	}, {
		id: 2,
		icon: require("../../../_assets/images/icons/realtors-icon-2.svg"),
		title: "conduct showings",
		description: "Qualified applicants can schedule showings at their convenience and our team will manage all in person and digital walkthroughs.",
	}, {
		id: 3,
		icon: require("../../../_assets/images/icons/realtors-icon-3.svg"),
		title: "photos, videos & listing creation",
		description: "We take professional photos, 360 videos and craft the best ads to bring the perfect tenant into your property.",
	}, {
		id: 4,
		icon: require("../../../_assets/images/icons/realtors-icon-4.svg"),
		title: "lease creation",
		description: "We prepare the lease agreement for you, with our proprietary lease appendix, to make sure that your investment is secure and protected.",
	}, {
		id: 5,
		icon: require("../../../_assets/images/icons/realtors-icon-5.svg"),
		title: "application interviews",
		description: "Prior to booking any showings we interview applicants and collect all their basic information, to make sure only the best and most interested applicants come to showings.",
	}, {
		id: 6,
		icon: require("../../../_assets/images/icons/realtors-icon-6.svg"),
		title: "property promotion",
		description: "We promote your property across multiple platforms to ensure we get all the best potential tenants coming your way.",
	}, {
		id: 7,
		icon: require("../../../_assets/images/icons/realtors-icon-7.svg"),
		title: "credit, background and reference checks",
		description: "With best-in-class screening tools we check an applicant’s credit, criminal history, social media, confirm their employment, past tenancy, ID verification and call references.",
	}, {
		id: 8,
		icon: require("../../../_assets/images/icons/realtors-icon-8.svg"),
		title: "tenant handoff",
		description: "Once the lease and appendix has been signed we schedule a time to handoff your tenant to you, making sure everyone has the info they need to start an amazing tenancy.",
	},
];
const referralTabTwoData = [
	{
		id: 1,
		icon: require("../../../_assets/images/icons/realtors-icon-1.svg"),
		title: "property & rent assessment",
		description: "Even before you sign on the dotted line we will do a free inspection of the property and give you a full assessment to figure out rent prices and ideal tenant profiles",
	}, 
	{
		id: 2,
		icon: require("../../../_assets/images/icons/realtors-icon-9.svg"),
		title: "collect rent",
		description: "Direct deposit is set up for all of your tenants, approved maintenance payments are paid automatically and rent is deposited right into your account.",
	}, {
		id: 3,
		icon: require("../../../_assets/images/icons/realtors-icon-10.svg"),
		title: "preparing the property",
		description: "We will repair and manage upgrades to make sure your place is ready to be rented and you can get top dollar rent.",
	}, {
		id: 4,
		icon: require("../../../_assets/images/icons/realtors-icon-11.svg"),
		title: "tenant management",
		description: "Don’t worry about the day-to-day calls from your tenant. We take care of everything so you can focus on the things that matter most in your life.",
	}, {
		id: 5,
		icon: require("../../../_assets/images/icons/realtors-icon-3.svg"),
		title: "photos, videos & listing creation",
		description: "We take professional photos, 360 videos and craft the best ads to bring the perfect tenant into your property.",
	}, {
		id: 6,
		icon: require("../../../_assets/images/icons/realtors-icon-12.svg"),
		title: "contractor & trade management",
		description: "From small upgrades to complete renos, we bring in the best team, get you preferred pricing and manage the job from start to finish.",
	}, {
		id: 7,
		icon: require("../../../_assets/images/icons/realtors-icon-13.svg"),
		title: "applicant screening",
		description: "We use best-in-class screening tools to check an applicant’s credit, background, criminal history, social media and confirm past employment and tenancy.",
	}, {
		id: 8,
		icon: require("../../../_assets/images/icons/realtors-icon-14.svg"),
		title: "legal notices & evictions",
		description: "Our experienced managers, strict process and legal partners make sure that the proper steps are taken to keep your property protected and your unit tenanted.",
	}, {
		id: 9,
		icon: require("../../../_assets/images/icons/realtors-icon-2.svg"),
		title: "conduct showings",
		description: "Interested applicants can schedule showings at their convenience and our team will manage all visits and applications.",
	}, {
		id: 10,
		icon: require("../../../_assets/images/icons/realtors-icon-15.svg"),
		title: "maintenance & repairs",
		description: "Snow removal, lawns and all repairs are taken care of, so you’ve got peace of mind 365 days a year.",
	}, {
		id: 11,
		icon: require("../../../_assets/images/icons/realtors-icon-4.svg"),
		title: "lease creation",
		description: "We prepare the lease agreement for you, with our proprietary lease appendix, to make sure that your investment is secure and protected.",
	}, {
		id: 12,
		icon: require("../../../_assets/images/icons/realtors-icon-16.svg"),
		title: "move in/out inspections",
		description: "We perform bi-annual, as well as move in/move out inspections and provide detailed reports so everything is documented from start to finish.",
	}, {
		id: 13,
		icon: require("../../../_assets/images/icons/realtors-icon-17.svg"),
		title: "onboarding tenants",
		description: "A smooth transition is key to make the tenant feel comfortable, communication flow easily and rent comes through uninterrupted.",
	}, {
		id: 14,
		icon: require("../../../_assets/images/icons/realtors-icon-18.svg"),
		title: "owner statements",
		description: "With us you’ll get monthly reports and on-demand owner statements, so you are always in the know.",
	},
];

const Referral = (props) => {
    return (
		<section className="referral-container">
			<Container>
				<h2 className="subHead">did your client just purchase an investment property? refer them our way and we will take care of them like family and send you a little something as thanks!</h2>

				<Tab.Container id="left-tabs-example" defaultActiveKey="first">					
					<div className="justify-content-center d-flex">
						<Nav variant="pills">
							<Nav.Item>
								<Nav.Link eventKey="first">tenant acquisition service</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="second">property management service</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>
					<Tab.Content>
							<Tab.Pane eventKey="first">
								{referralTabOneData &&
									(referralTabOneData.length > 0) ?
									(<Row>
										{referralTabOneData.map(littp => {
											return <ToolsInfo key={littp.id} icon={littp.icon} title={littp.title} description={littp.description} />
										})}
									</Row>)
									: <Loader />
								}
							</Tab.Pane>
							<Tab.Pane eventKey="second">								
									{referralTabTwoData &&
										(referralTabTwoData.length > 0) ?
										(<Row>
											{referralTabTwoData.map(littp => {
												return <ToolsInfo key={littp.id} icon={littp.icon} title={littp.title} description={littp.description} />
											})}
										</Row>)
										: <Loader />
									}								
							</Tab.Pane>
						</Tab.Content>
				</Tab.Container>
			</Container>
		</section>        
    )
}

export default withRouter(Referral);
