import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const ImportToolPreviewDescription = (props) => {
  const previewDetails = props.previewDetails;
  const history = useHistory();

  const role = Number(Cookies.get("role"));
  return (
    <div className={role === 1 ? 'inner-wrapper for-admin' : 'inner-wrapper'}>
      <h6>Description</h6>
        {previewDetails && previewDetails.description ?
          <p style={{whiteSpace: "pre-wrap"}} className="description-property">
            <textarea className="importtool-description-property-textbox">{previewDetails.description}</textarea>
          </p>
        : ''}
    </div>
  );
}
export default ImportToolPreviewDescription;