import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Moment from 'react-moment';
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { EditButton, LargeButton } from "../../App/components";
import { bathroomTitle, bedroomTitle, logger, sharedBathroomTitle } from "../../Utils";
import { DollorNumberDisplay, ModalBox } from "../../App/components";
import { useSelector } from "react-redux";
import Geocode from "react-geocode";
import { Google_Map_API } from "../../Constants/endPoints";
import EditableLabel from 'react-inline-editing';


const log = logger("ImportToolPreviewUnitAddress");
const ImportToolPreviewUnitAddress = (props) => {
  const { editDetails, requestShowing, messageLandloard, myFavourite, is_favourite } = props;
  const role = useSelector(state => state.auth.role) || parseInt(Cookies.get('role'));
  const previewDetails = props.previewDetails;
  // const unitName = ((previewDetails && previewDetails?.property_type === 1) || role === 3) ?
  //   (previewDetails && (isNaN(previewDetails.unit_no) ? previewDetails.unit_no : previewDetails.unit_no && ('Unit ' + previewDetails.unit_no)))
  //   : (previewDetails && (isNaN(previewDetails.unit_no) ? previewDetails.unit_no : ('Unit ' + previewDetails.unit_no)));
  const [confirmAction, setConfirmAction] = useState(false);
  const history = useHistory();
  const [isTour, setIsTour] = useState(false);
  const [googleAddress, setGoogleAddress] = useState('');
  
  const [addressIsEditing, setAddressIsEditing] = useState(true);
  const [pricePerMonthIsEditing, setPricePerMonthIsEditing] = useState(false);
  const [bedroomCountIsEditing, setBedroomCountIsEditing] = useState(false);
  const [bathroomCountIsEditing, setBathroomCountIsEditing] = useState(false);

  useEffect(() => {
    if (history.location.pathname.indexOf("tour") > 0) {
      setIsTour(true);
    }
  }, [history.location.pathname]);
  
  const showEditAddressDetails = () => {
    setAddressIsEditing(true);
    setPricePerMonthIsEditing(true);
    setBedroomCountIsEditing(true);
    setBathroomCountIsEditing(true);
  }

  const handleClick = (clickFor) => {
    log.info("isTour", isTour)
    if (Cookies.get('access_token') && !isTour) {
      if (clickFor === "myFavourite") {
        myFavourite();
      } else if (clickFor === "requestShowing") {
        requestShowing();
      } else if (clickFor === "messageLandloard") {
        messageLandloard();
      }
    } else {
      setConfirmAction(true);
    }
  }
  const getSignedUp = () => {
    setConfirmAction(false);
    history.push('/signup/tenant');
  }

  useEffect(() => {
    if (previewDetails && previewDetails?.hide_exact_address === 1) {
      const latValue = previewDetails?.latitude || '';
      const lngValue = previewDetails?.longitude || '';
      Geocode.setApiKey(Google_Map_API);
      Geocode.enableDebug();
      Geocode.fromLatLng(latValue, lngValue).then(
        (response) => {
          const address = response?.results[0]?.formatted_address;
          setGoogleAddress(address);
        },
        (error) => { }
      );
    }
  }, [previewDetails]);

  return (
    <div className={role === 1 ? 'inner-wrapper for-admin' : 'inner-wrapper'}>
      <Row>
        <Col md="11">
          <h2 className="lp-property-details-title">
            <div>
              <EditableLabel text='Address'
                  isEditing={addressIsEditing}
                  labelClassName='lp-import-property-details-title-label'
                  inputClassName='lp-import-property-details-title-input'
                  inputWidth='500px'
                  inputHeight='25px'
                  inputMaxLength='150'
                  labelFontWeight='bold'
                  inputFontWeight='bold'
                  text={previewDetails?.address ? previewDetails?.address + ' ' : ''}
              />
            </div>
            <Image
              className="icon"
              width="20px"
              height="20px"
              src={(previewDetails?.is_verified === 'unverified') || (previewDetails?.is_verified === 'unverified') ? require("../../_assets/images/Unverified.png")
                : require("../../_assets/images/verified.png")}
            />
          </h2>
        </Col>
        <Col md="1">
          {myFavourite &&
            <div className="icon-wrapper pull-right cursorPointer" onClick={() => handleClick("myFavourite")}>

              <Image
                className="icon"
                width="30px"
                height="28px"
                src={is_favourite ? require("../../_assets/images/love-icon.svg") : require("../../_assets/images/love-icon-hollow.svg")}
              />
            </div>
          }
        </Col>
      </Row>


      {(previewDetails) &&
        <>
          {(editDetails) ?
            <>
              {previewDetails?.hide_exact_address === 1 && <div className="googleAddress mb-2" style={{color: "#4f4f58"}}><strong>Google address:</strong> {googleAddress}</div>}

              {previewDetails ?
                <>
                  <h4 className="lp-property-details-sub-title">{previewDetails?.property_type === 1 ? 'Full Property' : previewDetails?.property_type === 2 ? 'Unit' : previewDetails?.property_type === 3 ? 'Room for Rent' : '' } - <DollorNumberDisplay price={previewDetails?.price_per_month} /> / month</h4>
                  <ul className='features'>
                    {previewDetails && previewDetails?.property_type !== 3 ?
                      <>
                        {previewDetails?.bedrooms ? <li><Image className="icon" src={require("../../_assets/images/features/bed.svg")} alt="bedroom" />
                          {previewDetails && bedroomTitle(previewDetails?.bedrooms)} Bedroom</li> : ''}
                        {previewDetails?.bathrooms ? <li><Image className="icon" src={require("../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                          {previewDetails && bathroomTitle(previewDetails?.bathrooms)} Bathroom</li> : ''}
                      </> :

                      <li>
                        <Image className="icon" src={require("../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                        {previewDetails && sharedBathroomTitle(previewDetails?.is_shared_bathroom)} Bathroom
                  </li>
                    }

                    <li><Image className="icon" src={require("../../_assets/images/features/lease.svg")} alt="lease" />
                      {previewDetails && ((previewDetails?.lease_duration === 1 ? '1 year lease' : '') || (previewDetails?.lease_duration === 2 ? 'Month to month lease' : ''))}
                    </li>
                    <li><Image className="icon" src={require("../../_assets/images/features/calender.svg")} alt="available" />Available on <Moment format="MMMM D, YYYY">{previewDetails && previewDetails.available_date}</Moment></li>
                  </ul>
                </> : ''}
            </>
            : ''}
          {(previewDetails) ? <>
            {(previewDetails && (previewDetails?.available_date)) ?
              <>
                <h4 className="lp-property-details-sub-title">{previewDetails?.property_type === 1 ? 'Full Property' : previewDetails?.property_type === 2 ? 'Unit' : previewDetails?.property_type === 3 ? 'Room for Rent' : '' } -
                &nbsp;
                  $
              <EditableLabel 
                  isEditing={pricePerMonthIsEditing}
                  labelClassName='lp-property-details-price-editable-label'
                  inputClassName='lp-property-details-price-editable-input'
                  inputWidth='200px'
                  inputHeight='25px'
                  inputMaxLength='50'
                  labelFontWeight='bold'
                  inputFontWeight='bold'
                  text={previewDetails?.price_per_month} />
                / month</h4>
                <ul className='features'>
                  {previewDetails && previewDetails?.property_type !== 3 ?
                    <>
                      {previewDetails?.bedroom_count ? <li className="lp-property-details-bedroom-count-editable-parent"><Image className="icon" src={require("../../_assets/images/features/bed.svg")} alt="bedroom" />
                        <EditableLabel 
                          isEditing={bedroomCountIsEditing}
                          labelClassName='lp-property-details-bedroom-count-editable-label'
                          inputClassName='lp-property-details-bedroom-count-editable-input'
                          inputWidth='200px'
                          inputHeight='25px'
                          inputMaxLength='50'
                          labelFontWeight='bold'
                          inputFontWeight='bold'
                          text={previewDetails && bedroomTitle(previewDetails?.bedroom_count)} /> Bedroom</li> : ''}
                      {previewDetails?.bathroom_count ? <li className="lp-property-details-bathroom-count-editable-parent"><Image className="icon" src={require("../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                        <EditableLabel 
                          isEditing={bathroomCountIsEditing}
                          labelClassName='lp-property-details-bathroom-count-editable-label'
                          inputClassName='lp-property-details-bathroom-count-editable-input'
                          inputWidth='200px'
                          inputHeight='25px'
                          inputMaxLength='50'
                          labelFontWeight='bold'
                          inputFontWeight='bold'
                          text={previewDetails && bathroomTitle(previewDetails?.bathroom_count)} /> Bathroom</li> : ''}
                    </> :

                    <li>
                      <Image className="icon" src={require("../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                      {previewDetails && sharedBathroomTitle(previewDetails?.is_shared_bathroom)} Bathroom
                  </li>
                  }

                  <li><Image className="icon" src={require("../../_assets/images/features/lease.svg")} alt="lease" />
                    {previewDetails && ((previewDetails?.lease_duration === 1 ? '1 year lease' : '') || (previewDetails?.lease_duration === 2 ? 'Month to month lease' : ''))}
                  </li>
                  <li><Image className="icon" src={require("../../_assets/images/features/calender.svg")} alt="available" />Available on <Moment format="MMMM D, YYYY">{previewDetails && previewDetails?.available_date}</Moment></li>
                </ul>
              </> : ''}
          </> : ''}
          
          <div className='button-wrapper'>
            <EditButton onClick={showEditAddressDetails} />
          </div>
        </>}

      <ModalBox
        show={confirmAction}
        onHide={() => setConfirmAction(false)}
        size='sm'
        actionbuttontitle={'get started'}
        buttonaction={() => getSignedUp()}
      >
      </ModalBox>
    </div> 
  );
}
export default ImportToolPreviewUnitAddress;