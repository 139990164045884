import React, { useEffect, useState } from "react";
import { withRouter, NavLink, useLocation, useHistory } from 'react-router-dom';
import { Nav } from "react-bootstrap";
import "./style.scss";
import { AppLayout, PageTitleHeader, Calender, RequestShowing, ConfirmShowing, TenancyForm, ModalBox, ShareListing, ShowAnalytics } from "../../components";
import { CreateLeaseOptions } from "../../components/LeaseManagement";
import { AddingTenants, LeasePdfView } from "../../components/LeaseManagement/CreateLeaseSteps";
import { LeaseDuration, CheckListTenant } from "../../components/LeaseManagement/UploadLease";
import PropertyDetails from "./PropertyDetails";
import LandlordMessages from "./LandlordMessages";
import Leases from "./Leases";
import { getAPI, postAPI } from "../../../Api";
import { useDispatch, useSelector } from 'react-redux';
import { setPropertyDetail, getUnitDetail } from "../../../Store/actions/PropertyDetailAction";
import { getCalenderData, setReceiverData, setTenancyApplicationId, setTenantId, setViewingRequestId } from "../../../Store/actions/MessageDetailAction";
import ViewTenantProfile from "./ViewTenantProfile";
import { PurchaseReport, PaymentMethod } from "../../components/CertnIntegration";
import { DeactivationOptions } from "../../components/DeactivateListingManagement";
import { ProfessionalPhotoShoot } from "../CreateListing/Steps";
import { getCurrentPage } from "../../../Store/actions/PropertyDetailAction";
import { logger, toaster } from "../../../Utils";
import CompleteWarningMessage from "../../components/CompleteWarningMessage/CompleteWarningMessage";
import Cookies from 'js-cookie';
import useMedia from "../../../hooks/useMedia";

const log = logger('RootPropertyDetails')
const RootPropertyDetails = (props) => {
    const history = useHistory()
    const isMobile = useMedia('(max-width: 600px)');
    const [showArrowButton, setShowArrowButton] = useState(true);
    const [hideArrowBackButton, setHideArrowBackButton] = useState(false);

    const role = Number(Cookies.get('role'));
    const [property_id, setProperty_id] = useState('');
    const [is_publishSuccess, setPublishSuccess] = useState(false);
    const [property_details_id, setProperty_details_id] = useState('');
    const [componentLoad, setProperty_component_load] = useState('');
    const [is_showingRequest, setShowingRequest] = useState(false);
    const [is_confirmShowing, setConfirmShowing] = useState(false);
    const [is_reSchedule, setReSchedule] = useState(false);
    const [is_showingProfile, setShowingProfile] = useState(false);
    const [is_invite_apply, setInvite_apply] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchTenant, setSearchTenant] = useState(false);
    const [clickType, setClickType] = useState('');
    const [uploadLease, setUploadLease] = useState(false);
    const [addTenant, setAddTenant] = useState(false);
    const [tenantList, setTenantList] = useState(null);
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [leaseOptionsScreen, setLeaseOptionsScreen] = useState(false);
    const [lease_id, setLeaseId] = useState(null);
    const [isCertnPurchaseReport, setCertnPurchaseReport] = useState(false);
    const [isCertnPaymentMethod, setCertnPaymentMethod] = useState(false);
    const [is_proceed_for_payment, setIs_proceed_for_payment] = useState(null);
    const [isCertnPurchaseModel, setCertnPurchaseModel] = useState(false);
    const [is_LeasePdfViewScreen, setLeasePdfViewScreen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [loader, setLoader] = useState(false)

    const [is_deactivateReasonOptions, setDeactivateReasonOptions] = useState(false);
    const [deactivateReason, setDeactivateReason] = useState(false);
    const [deactivateDescription, setDeactivateDescription] = useState('');
    const [leaseOptionsScreenFromDeactive, setLeaseOptionsScreenFromDeactive] = useState(false);
    const [is_getVerified, setGetVerified] = useState(false);

    const [is_sharedListing, setSharedListing] = useState(false);
    const [is_sharedAnalytics, setSharedAnalytics] = useState(false);
    const [pdf, setPdf] = useState('');

    const [isDeactivation, setIsDeactivation] = useState(false);
    const [skipModal, setSkipModal] = useState(false);
    const [returnPath, setReturnPath] = useState(false);
    const [deactiveSuccessModalShow, setDeactiveSuccessModalShow] = useState(false);
    const [leaseBackStep, setLeaseBackStep] = useState(null);

    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const receiverData = useSelector(state => state.messageDetailsReducer.receiverData);
    const headerContent = useSelector(state => state.messageDetailsReducer.headerContent);
	const conversation_id = useSelector(state => state.messageDetailsReducer.conversationId);
    const tenantId = useSelector(state => state.messageDetailsReducer.tenantId);
    log.info("receiverData ===> ", receiverData);

    useEffect(() => {
        if (isMobile && componentLoad === 'messages'
            && history.location.pathname.includes("/messages")
            && tenantId !== null && !showArrowButton) {
            setHideArrowBackButton(true)
        } else {
            setHideArrowBackButton(false)
        }
    }, [history, isMobile, componentLoad, tenantId, showArrowButton])

    useEffect(() => {
        const eventObj = props.location.state
        const tenancy_application_id = eventObj?.tenancy_application_id

        if (tenancy_application_id) {
            dispatch(setReceiverData(null));
            dispatch(getCalenderData(null));
            dispatch(setViewingRequestId(null));
            dispatch(setTenancyApplicationId(tenancy_application_id));
            setInvite_apply(true)
            return
        }
        if (eventObj && Object.keys(eventObj).length) {
            dispatch(getCalenderData(eventObj));
            dispatch(setReceiverData(null));
            dispatch(setViewingRequestId(eventObj.viewingRequestId))
             if (eventObj.viewingRequestId && conversation_id)
                {
                    setConfirmShowing(true);
                }
        }

        return(()=>{
            if(componentLoad === undefined )
      {      setProperty_id(null);
            setProperty_details_id(null);
            dispatch(setPropertyDetail(null));
            dispatch(getUnitDetail(null));}


        });
       
    }, [])


    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
        const componentSlug = props.match.params.slug
        if (componentSlug === "leases" && unitDetails?.status === 4) {
            props.history.push('/rented-listing')
            return
        }

        setProperty_component_load(componentSlug)

    }, [props]);


    useEffect(() => {
        if (property_id) {
            getPropertyDetails();
        }
        return () => {
            // dispatch(getUnitDetail({}))
        }
    }, [property_id, is_getVerified])

    //Redirection from ConfirmShowing
    useEffect(() => {
        const tenant_id = location.tenant_id;
        if (tenant_id) {
            dispatch(setTenantId(tenant_id))
        }
    }, [location.pathname]);

    const setSelectedTenantList = (data) => {
         log.info("selectedTenantList", data);
        setUpdate(true);
        setSelectedTenant(data);
    }

    const handleAddSearchTenant = (flag, clickType) => {
        setSearchTenant(flag);
        setClickType(clickType);
    }

    const getPropertyDetails = () => {
        // debugger
        setLoader(true)
        const url = '/landlord/' + property_id + '/property-details';
        getAPI(url).then(response => {
            if (response.status !== 200) {
                setLoader(false)
                backToDashboard();
                toaster('error', response.response.data.message);
            } else if (response.status === 200) {
                const res = response.data.data;
                setLoader(false)
                dispatch(setPropertyDetail(res));
                res?.details?.length > 0 && res.details.map((data) => {
                    if (data.id === property_details_id) {
                        dispatch(getUnitDetail(data))
                    }
                    return data;
                })
            }
        })
            .catch(error => {
                setLoader(false)
            });
    }

    const backToDashboard = () => {
        if (props?.location?.state?.backPath) {
            props.history.push(props?.location?.state?.backPath);
        } else {
            if (unitDetails?.is_active) {
                props.history.push('/dashboard');
            } else {
                props.history.push('/deactivated-listing');
            }
        }

        // dispatch(getUnitDetail(null))
        // dispatch(setPropertyDetail(null))
    }
    const callTenantprofile = (data) => {
        setShowingProfile(data);
    }

    const handleCloseInviteApply = (data) => {
        setInvite_apply(data);
        props.history.push({
            pathname: `/property-details/${property_id}/${property_details_id}/messages`
        });
    }

    const editDetails = (step) => {
        let url = `/create-listing/${property_id}`;
        if (property_details_id) {
            if (unitDetails?.status === 2) {
                url = `/create-listing/${property_id}/${property_details_id}`;
            } else {
                url = `/edit-listing/${property_id}/${property_details_id}`;
            }
        }
        dispatch(getCurrentPage(step));
        props.history.push({
            pathname: url
        });
    }

    const onSubmit = () => {
        const url = '/landlord/publish-listing';
        const data = {
            property_id: property_id,
            property_details_id: property_details_id
        }
        postAPI(url, data).then(response => {
            if (response.status === 200) {
                setPublishSuccess(true);
                //toaster('success', response.data.message);
            } else {
                toaster('error', 'Something went wrong!')
            }
        })
    }

    useEffect(() => {
        log.info("selectedTenant", selectedTenant);
        setSelectedTenant(selectedTenant);
        setUpdate(false);
    }, [selectedTenant, update])

    const onClickYes = () => {
        log.info({
            'deactivate_reason': deactivateReason && deactivateReason,
            'deactivate_description': deactivateDescription && deactivateDescription,
        });
        const url = '/landlord/listing/deactive/' + property_details_id;
        const data = {
            'deactivate_reason': deactivateReason && deactivateReason,
            'deactivate_description': deactivateDescription && deactivateDescription,
        }
        postAPI(url, data).then(res => {
            if (res.status === 200) {
                setSkipModal(false);
                setDeactiveSuccessModalShow(true);
                getPropertyDetails();
            }
        })

    }
    const onClickNo = () => {
        log.info("Return Path", returnPath);
        // setAddTenant(true);
        if (returnPath === 'addTenant') { setAddTenant(true); }
        if (returnPath === 'checklistTenant') { setSearchTenant(true); }

        setSkipModal(false)
    }

    const propertyTypeAddress = {
        1: 'full property',
        2: 'Unit in building/house',
        3: 'Room for rent',
    }

    let finalAddress = ''
    if(propertyDetails?.address){
        if(propertyDetails?.address[propertyDetails?.address.trim().length - 1] === ','){
           finalAddress = propertyDetails?.address.replace(propertyDetails?.address[propertyDetails?.address.trim().length - 1],'')
           const detailsItem=propertyDetails?.details?.length && propertyDetails?.details[0];
           if(propertyDetails?.property_type === 2){
               if(propertyDetails?.details !== undefined) finalAddress = finalAddress + ' - '+  detailsItem?.unit_no 
           }else if(propertyDetails?.property_type === 3){
                finalAddress = finalAddress + ' - '+ detailsItem?.unit_no
           }
        }else{
            const detailsItem=propertyDetails?.details?.length && propertyDetails?.details[0];

            if(propertyDetails?.property_type === 2){
                if(propertyDetails?.details !== undefined) finalAddress = propertyDetails?.address + ' - '+ detailsItem?.unit_no 
           }else if(propertyDetails?.property_type === 3){
                finalAddress = propertyDetails?.address + ' - '+ detailsItem?.unit_no
           }else{
                finalAddress = propertyDetails?.address
           }
        }
    }else{
        finalAddress = 'property details'
    }

    return (
        <>
            <AppLayout>
                <div className="profile">
                    {
                        !hideArrowBackButton && <PageTitleHeader
                        isMobile={isMobile}
                            title={finalAddress}
                            isArrow={hideArrowBackButton === true ? false : true}
                            onClick={() => backToDashboard()}
                            isInProgress={unitDetails && (unitDetails.formatted_status === 'in progress' || unitDetails.formatted_status === 'pending approval')}
                            threeDotNav={(unitDetails && unitDetails.is_active !== 0) && 'property-details'}
                            setDeactivateReasonOptions={(flag) => setDeactivateReasonOptions(flag)}
                            setSharedListing={(flag) => setSharedListing(flag)}
                            setSharedAnalytics={(flag) => setSharedAnalytics(flag)}
                        />
                    }
             

                    {
                        !unitDetails ?
                            <CompleteWarningMessage
                                message="Saved listing."
                                ctaText='Click here to finish'
                                onClick={() => editDetails(0)} />

                            : unitDetails && unitDetails?.utilities?.length < 1 && unitDetails.is_active !== 0 && unitDetails.status === 2 ?
                                <CompleteWarningMessage
                                    message="Saved listing."
                                    ctaText='Click here to finish'
                                    onClick={() => editDetails(2)} />

                                : unitDetails && !unitDetails.description && unitDetails.is_active !== 0 && unitDetails.status === 2 ?
                                    <CompleteWarningMessage
                                        message="Saved listing."
                                        ctaText='Click here to finish'
                                        onClick={() => editDetails(3)} />

                                    : (unitDetails && unitDetails?.images?.length === 0) && unitDetails.is_active !== 0 && unitDetails.status === 2 ?
                                        <CompleteWarningMessage
                                            message="Saved listing."
                                            ctaText='Click here to finish'
                                            onClick={() => editDetails(4)} />

                                        : (unitDetails && unitDetails.formatted_status === 'in progress') && unitDetails.is_active !== 0 && unitDetails.status === 2 ?
                                            <CompleteWarningMessage
                                                message="Please review your listing."
                                                ctaText='Click here to publish'
                                                onClick={() => onSubmit()}
                                                colorStyle='success'
                                            />
                                            :
                                            <Nav fill variant="tabs" className="rp-tab-views">
                                                {(unitDetails?.status !== 4) && <NavLink to={{ pathname: '/property-details/' + property_id + '/' + property_details_id + '/unit-details' }} className={componentLoad === "unit-details" || componentLoad === undefined ? "nav-link active" : "nav-link"} >Unit Details</NavLink>}
                                                <NavLink to={{ pathname: '/property-details/' + property_id + '/' + property_details_id + '/messages' }} className={"nav-link"} >Messages</NavLink>
                                                {(unitDetails?.status !== 4) && <NavLink to={{ pathname: '/property-details/' + property_id + '/' + property_details_id + '/showings' }} className={"nav-link"} >Showings</NavLink>}
                                                {(unitDetails?.status !== 4) && <NavLink to={{ pathname: '/property-details/' + property_id + '/' + property_details_id + '/leases' }} className={"nav-link"} >Leases</NavLink>}
                                            </Nav>
                    }


                    {componentLoad === "unit-details" || componentLoad === undefined ?
                        <PropertyDetails loader={loader} setGetVerified={(flag) => setGetVerified(flag)} />
                        : ""}

                    {componentLoad === "messages" ?
                        <LandlordMessages
                            is_reSchedule={is_reSchedule}
                            setReSchedule={(flag) => setReSchedule(flag)}
                            setShowingRequest={(flag) => setShowingRequest(flag)}
                            setConfirmShowing={(flag) => setConfirmShowing(flag)}
                            callTenantprofile={(data) => callTenantprofile(data)}
                            setInvite_apply={(flag) => setInvite_apply(flag)}
                            setLeaseOptionsScreen={(flag) => setLeaseOptionsScreen(flag)}
                            setCertnPurchaseReport={(flag) => setCertnPurchaseReport(flag)}
                            setLeasePdfViewScreen={(flag) => setLeasePdfViewScreen(flag)}
                            setShowTenantList={(data) => setShowArrowButton(data)}
                        /> : ""}

                    {componentLoad === "showings" ?
                        <Calender
                            user_type={'landlord'}
                            setConfirmShowing={(flag) => setConfirmShowing(flag)}
                        />
                        : ""}

                    {componentLoad === "leases" ?
                        <Leases
                            setIsDeactivation={setIsDeactivation}
                            setUploadLease={(data) => setUploadLease(data)}
                            setLeaseId={(data) => setLeaseId(data)} />
                        : ""}
                </div>
            </AppLayout>
            <RequestShowing
                isShow={is_showingRequest}
                title={is_reSchedule ? 'submit a different schedule' : 'creating showing invitation'}
                handleClose={() => setShowingRequest(false)}
                is_reSchedule={is_reSchedule}
                setReSchedule={(flag) => setReSchedule(flag)}
            />

            <ConfirmShowing
                isShow={is_confirmShowing}
                title={'showing invitation'}
                handleClose={() => setConfirmShowing(false)}
                setShowingRequest={(flag) => setShowingRequest(flag)}
                setReSchedule={(flag) => setReSchedule(flag)}
            />

            <ViewTenantProfile
            
                property_id={property_id}
                property_details_id={property_details_id}
               isShow={is_showingProfile}
                handleClose={() => callTenantprofile(false)}
                title={'Applicant’s Profile'}
                setInvite_apply={(flag) => setInvite_apply(flag)}
                setLeaseOptionsScreen={(flag) => setLeaseOptionsScreen(flag)}
                setCertnPurchaseReport={(flag) => setCertnPurchaseReport(flag)}

            />


            <TenancyForm
                isShow={is_invite_apply}
                title={'invite to apply'}
                handleClose={() => handleCloseInviteApply(false)}
                role={role}
                name={headerContent && headerContent.name}
                setLeaseOptionsScreen={(flag) => setLeaseOptionsScreen(flag)}
                setCertnPurchaseReport={(flag) => setCertnPurchaseReport(flag)}
                setShowingProfile={(flag) => setShowingProfile(flag)}
            />

            {/* Start of Lease Module */}
            {update === false && <CheckListTenant
                isShow={searchTenant}
                handleClose={() => setSearchTenant(false)}
                clickType={clickType}
                setAddTenant={(flag) => setAddTenant(flag)}
                newTenantList={tenantList}
                setSelectedTenant={(data) => setSelectedTenantList(data)}
                selectedTenantList={selectedTenant}
                setUploadLease={(flag) => setUploadLease(flag)}
                apiCallback={() => setUploadLease(true)}
                setSkipOpen={(data) => setSkipModal(data)}
                setReturnPath={setReturnPath}
                setOpenDeactivate={(data) => setDeactivateReasonOptions(data)}
                setLeaseOptionsScreenFromDeactive={(flag) => setLeaseOptionsScreenFromDeactive(flag)}
                deactivateReason={deactivateReason}
                isDeactivation={isDeactivation}
                leaseBackStep={leaseBackStep}
            />}

            <AddingTenants
                isShow={addTenant}
                handleClose={() => setAddTenant(false)}
                sendBackRes={(flag) => setSearchTenant(flag)}
                setTenantList={(data) => setTenantList(data)}

                setLeaseOptionsScreen={(flag) => setLeaseOptionsScreenFromDeactive(flag)}
                setOpenDeactivate={(data) => setDeactivateReasonOptions(data)}
                setAddTenant={(flag) => setAddTenant(flag)}
                setSkipOpen={(data) => setSkipModal(data)}
                setReturnPath={setReturnPath}
                singleReasonData={deactivateReason}
                isDeactivation={isDeactivation}
            />

            {update === false && <LeaseDuration
                isShow={uploadLease}
                handleClose={() => setUploadLease(false)}
                setUploadLease={(flag) => setUploadLease(flag)}
                setSearchTenant={(flag) => handleAddSearchTenant(flag, 'search')}
                addtenant={(flag) => handleAddSearchTenant(flag, 'add')}
                setSelectedTenant={(data) => setSelectedTenantList(data)}
                setLeaseBackStep={(flag) => setLeaseBackStep(flag)}
                selectedTenant={selectedTenant}
                lease_id={lease_id}
                setPdf={(data) => setPdf(data)}
                pdf={pdf}

                isDeactivation={isDeactivation}
            />}

            <CreateLeaseOptions
                isShow={leaseOptionsScreen}
                handleClose={() => setLeaseOptionsScreen(false)}
                setUploadLease={(data) => setUploadLease(data)}
            />

            <LeasePdfView
                isShow={is_LeasePdfViewScreen}
                handleClose={() => setLeasePdfViewScreen(false)}
                lease_id={receiverData && receiverData.property_lease_id}
                role={2}
                isUpdated={receiverData?.is_updated}
                is_sent_to_tenant={receiverData?.is_sent_to_tenant}
            />

            {/* End of Lease Module */}

            {/*Certn Integration*/}
            {isCertnPurchaseReport &&
                <PurchaseReport
                    isShow={isCertnPurchaseReport}
                    handleClose={() => {
                        setCertnPurchaseReport(false)  ;
                        setShowingProfile(false)}}
                    setCertnPaymentMethod={(flag) => setCertnPaymentMethod(flag)}
                    setIs_proceed_for_payment={(data) => setIs_proceed_for_payment(data)}
                    setCertnPurchaseModel={(flag) => {
                        setCertnPurchaseModel(flag) 
                        setShowingProfile(false)
                    }}
                    conversation_id={conversation_id}
                />}


            {isCertnPaymentMethod &&
                <PaymentMethod
                    isShow={(isCertnPaymentMethod && is_proceed_for_payment) && true}
                    handleClose={() => setCertnPaymentMethod(false)}
                    setCertnPurchaseModel={(flag) => setCertnPurchaseModel(flag)}
                    setCertnPaymentMethod={()=>setCertnPaymentMethod(false)}
                />}

            {/* Edit Success Model */}
            <ModalBox
                show={isCertnPurchaseModel}
                onHide={() => {
                    setCertnPurchaseModel(false)
                }}
                size="sm"
                actionbuttontitle={'got it'}
                buttonaction={() => {
                    setCertnPurchaseModel(false)
                }}
            >
                <h4>thank you for your purchase! </h4>
            <p>
            We will send you the report as soon as it is processed. If the report cannot be generated successfully (not enough tenant info or they choose to not consent), we will let you know and you will not be charged.
            </p>
            </ModalBox>

            {/* Deactivate Listing Management */}
            <DeactivationOptions
                isShow={is_deactivateReasonOptions}
                handleClose={() => setDeactivateReasonOptions(false)}
                setLeaseOptionsScreen={(flag) => setLeaseOptionsScreenFromDeactive(flag)}
                getPropertyDetails={() => getPropertyDetails()}
                setIsDeactivation={(flag) => setIsDeactivation(flag)}
                setLeaseBackStep={(flag) => setLeaseBackStep(flag)}

                setDeactivateDescription={(data) => setDeactivateDescription(data)}
                setDeactivateReason={(data) => setDeactivateReason(data)}
                setSearchTenant={data => setSearchTenant(data)}
                setAddTenant={data => setAddTenant(data)}
            />

            <CreateLeaseOptions
                isShow={leaseOptionsScreenFromDeactive}
                handleClose={() => setLeaseOptionsScreenFromDeactive(false)}
                title={'create a lease for this tenant, so you can collect rent, get repair requests & notices'}
                setUploadLease={(data) => setUploadLease(data)}

                setOpenDeactivate={(flag) => setDeactivateReasonOptions(flag)}
                deactivateReason={deactivateReason}
                isDeactivation={isDeactivation}
            />

            {/* Share Listing */}
            <ShareListing
                isShow={is_sharedListing}
                handleClose={() => setSharedListing(false)}
            />

            {is_sharedAnalytics === true &&
                <ShowAnalytics
                    isShow={is_sharedAnalytics}
                    handleClose={() => setSharedAnalytics(false)}
                />
            }
            {/* Get verified Lisitng Request */}
            <ProfessionalPhotoShoot
                isShow={is_getVerified}
                title={'Professional photo shoot'}
                subTitle={'For a limited time you can have professional photos of your unit taken by for FREE!'}
                type={1}
                handleClose={() => setGetVerified(false)} />

            <ModalBox
                show={is_publishSuccess}
                onHide={() => {
                    setPublishSuccess(false)
                }}
                size="sm"
                actionbuttontitle={'got it'}
                buttonaction={() => {
                    setPublishSuccess(false)
                    props.history.push('/dashboard');
                }}
            >
                <h4>your listing is being reviewed by our team for accuracy</h4>
                <p>you will get an email when it is approved</p>
            </ModalBox>
            <ModalBox
                show={skipModal}
                closeButtonHeader={false}
                size='sm'
                type='condition'
                actionbuttontitle={'Yes'}
                buttonaction={() => onClickYes()}
                backbuttontitle={'Cancel'}
                backaction={() => onClickNo()}
            >
                <h4>are you sure you want to<br /> {deactivateReason === 4 ? "skip and deactivate listing?" : "skip?"}</h4>
                <p className='order-one-modal'>you won’t get access to the management tool where you can collect rent, serve notices, record repairs and more!</p>
            </ModalBox>
            <ModalBox
                show={deactiveSuccessModalShow}
                onHide={() => {
                    setDeactiveSuccessModalShow(false);
                }}
                size="md"
                actionbuttontitle={"got it"}
                buttonaction={() => {
                    setDeactiveSuccessModalShow(false);
                }}
            >
                <h4>you have deactivated this listing</h4>
            </ModalBox>
        </>
    );
}

export default withRouter(RootPropertyDetails);