import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { HeaderLandingPage, FooterLandingPage } from '../components';
import { postAPI } from '../../Api';

import './style.scss';

const ListingGenerator = (props) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const validateUrl = (listingUrl) => {
    var pattern_kijijica = /^(http(s)?(:\/\/))?(www\.)?kijiji\.ca(\/.*)?$/;
    var pattern_kijijicom = /^(http(s)?(:\/\/))?(www\.)?kijiji\.com(\/.*)?$/;
    var pattern_facebookcom = /^(http(s)?(:\/\/))?(www\.)?facebook\.com(\/.*)?$/;
    
    if(pattern_kijijica.test(listingUrl) || pattern_kijijicom.test(listingUrl) || pattern_facebookcom.test(listingUrl)) return true;
    return false;
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // validate the url.
    if(validateUrl(e.target.generate_url.value)) {
      const url = "/import_tool/add_to_queue";
      const data = {
          "import_url": e.target.generate_url.value,
      }
      setSaveLoading(true);
      postAPI(url, data).then(response => {
          setSaveLoading(false);

          if(response.data.status == true && typeof response.data.data.previewId !== 'undefined' && response.data.data.previewId > 0) {
            props.history.push('/import/preview/' + response.data.data.previewId);
          }
      });
    } else {
      alert('Invalid listing URL. Please try again!');
    }
  }
  
  return (
    <>
      <Helmet>
        <title>Listing Generator | Rent Panda</title>
      </Helmet>
      <div className="generate-page">
      <HeaderLandingPage />
      <Container>
      <section className="sec-generator">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 generator-left">
              <h2 className="generator-title text-left">
                create once.
                <br /> list everywhere.
              </h2>
              <p className="generator-desc-title">
                Creating a listing on rent panda has never been easier. Take
                advantage of some of the most convenient rental tools out there
                by simply pasting the URL of your listing below.
              </p>
              <form className="generate-form" onSubmit={handleSubmit}>
                <Row>
                  <Col md={8}>
                  <input className="text-generate" type="text" name="generate_url" required/>
                    <div className="hint-wrap">
                        <ul>
                            <li>kijiji.ca/your-listing</li>
                            <li>facebook.com/marketplace/your-listing</li>
                        </ul>
                    </div>
                  </Col>
                  <Col md={4}>
                    <Button className="btn-generate" type="submit">
                      generate
                    </Button>
                  </Col>
                </Row>
              </form>
              <div className="text-center mb-4 d-lg-none">
                <Image
                  src={require('../../_assets/images/landing-pages/listing-generator.png')}
                  alt="Banner"
                />
              </div>
            </div>
            <div className="col-lg-6 generator-right">
              <div className="text-center d-none d-lg-block">
                <Image
                  src={require('../../_assets/images/landing-pages/listing-generator.png')}
                  alt="Banner"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      </Container>
      <FooterLandingPage/>
      </div>
    </>
  );
};

export default withRouter(ListingGenerator);
