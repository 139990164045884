export const isRentedToTenant = (tenantsArray, tenantId) => {
  console.log('TenantsArray', tenantsArray, tenantId);
  return tenantsArray.includes(tenantId);
};

export const getFullAddress = (propertyDetails = {}) => {
  let finalAddress = '';
  if (propertyDetails?.address) {
    if (
      propertyDetails?.address[propertyDetails?.address.trim().length - 1] ===
      ','
    ) {
      finalAddress = propertyDetails?.address.replace(
        propertyDetails?.address[propertyDetails?.address.trim().length - 1],
        ''
      );
      if (propertyDetails?.property_type === 2) {
        finalAddress = finalAddress + ' - ' + propertyDetails?.unit_no;
      } else if (propertyDetails?.property_type === 3) {
        finalAddress = finalAddress + ' - ' + propertyDetails?.unit_no;
      }
    } else {
      if (propertyDetails?.property_type === 2) {
        finalAddress =
          propertyDetails?.address + ' - ' + propertyDetails?.unit_no;
      } else if (propertyDetails?.property_type === 3) {
        finalAddress =
          propertyDetails?.address + ' - ' + propertyDetails?.unit_no;
      } else {
        finalAddress = propertyDetails?.address;
      }
    }
  } else {
    finalAddress = 'property details';
  }
  return finalAddress;
};

export const getGalaryItems = (images = [], videos = [], renderVideo) => {
  const VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const imagesGallaryArray = images.map((image, i) => {
    return {
      original: image,
      thumbnail: image,
    };
  });
  const videoGallaryArray = videos.map((video, i) => {
    return {
      original: `https://i.ytimg.com/vi_webp/${
        video.match(VID_REGEX)[1]
      }/0.webp`,
      thumbnail: `https://i.ytimg.com/vi_webp/${
        video.match(VID_REGEX)[1]
      }/sddefault.webp`,
      embedUrl: video,
      renderItem: renderVideo.bind(video),
    };
  });
  return [...imagesGallaryArray, ...videoGallaryArray];
};
