import React from 'react'
import { Row } from 'react-bootstrap'
import { Loader, PropertyCard } from '../../components'
import ListBlockContentLoader from '../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader'
import { formattedDetails } from './helper'

export default function DesktopListView(props) {
    const {
        showBottomLoader,
        propertyDetails,
        isLoading,
        isDesktop,
        isMapVisible,
        listData,
        selectedId } = props

    return (
        <div className={`search-listing-container ${(!isDesktop && isMapVisible === true) ? 'd-none' : 'd-block'}`}>
            <div className={(listData && listData?.data?.length === 0) ? "profile-container empty" : "profile-container"}>
                <div className={selectedId ? "profile-container-box outer-box-blur" : "profile-container-box"} >
                    {isLoading === true ? <Row><ListBlockContentLoader grid={6} /></Row> :
                        <>
                            {listData && listData?.data?.length !== 0 ?
                                <Row id={'searchList'}>
                                    {listData && listData.data?.map((data) => {
                                        return <React.Fragment key={"property" + data.id}>
                                            <PropertyCard
                                                price={data.price}
                                                id={data.property_details_id}
                                                isVerifed={data.is_verified}
                                                image_url={data.image_url}
                                                title={data.address}
                                                details={formattedDetails(data)}
                                                isDeleteIcon={data.isDeleteIcon}
                                                grid={6}
                                                onClick={() => propertyDetails(data.id, data.property_details_id)}
                                                isLoveIcon={data.is_favorite}
                                                selectedId={selectedId}
                                            />
                                        </React.Fragment>
                                    })}
                                    {showBottomLoader &&
                                        <Loader />}

                                </Row> :
                                <div className="profile-container-box empty-data">
                                    <Row>
                                        <h2 className="errorMsg">There are no rentals currently available based on the filters you chose.</h2>
                                    </Row>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
