import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { Image, Container } from "react-bootstrap";
import Cookie from "js-cookie";
// import RpImage from "../../components/Image";
import "./style.scss";
class FooterLandingPage extends Component {
  getRole = () =>{
    return parseInt(Cookie.get('role') || 0)
  }
  render() {
    const role = this.getRole();

    return (
      <React.Fragment>
        <footer className="landing-footer">
          <Container>
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4">
              <Link to={(role && role === 2) ? "/landlord#" : "/#"} className="landing-footer-logo">
                <Image
                  alt="Rent Panda"
                  src={require("../../../_assets/images/logo-color.svg")}
                  className="d-inline-block align-top"
                />
              </Link>
              <div className="follow-us-text">
                Follow us on social media
              </div>
              <div className="d-flex landing-footer-social-icons">
                <a href="https://www.facebook.com/RentPanda/" target="_blank" rel="noopener noreferrer" className="mr-4">
                  <Image
                    alt="Rent Panda"
                    src={require("../../../_assets/images/footer-facebook.svg")}
                    className=""
                  />
                </a>
                <a href="https://www.instagram.com/rentpanda/" target="_blank" rel="noopener noreferrer" className="mr-4">
                  <Image
                    alt="Rent Panda"
                    src={require("../../../_assets/images/footer-instagram.svg")}
                    className=""
                  />
                </a>
                <a href="https://www.linkedin.com/company/rent-panda" target="_blank" rel="noopener noreferrer" className="mr-4">
                  <Image
                    alt="Rent Panda"
                    src={require("../../../_assets/images/footer-linkedin.svg")}
                    className=""
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <h4 className="landing-footer-title">Company</h4>
              <ul className="landing-footer-list">
                <li>
                  <Link to="/about-us#">About Us</Link>
                </li>
                {/* <li>
                  <Link to="#">Contact Us </Link>
                </li> */}
                <li>
                  <Link to="/terms-and-conditions#">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy#">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <h4 className="landing-footer-title">Work With Us</h4>
              <ul className="landing-footer-list">
                <li>
                  <Link to="/for-panda-partners#">Advertise with us</Link>
                </li>
                <li>
                  <Link to="/for-realtors#">For Realtors</Link>
                </li>
                <li>
                  <Link to="/for-property-managers#">For Property Managers </Link>
                </li>
                {/* <li>
                  <Link to="/portals-and-auxillary/advertisers">For Advertisers</Link>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <h4 className="landing-footer-title">Resources</h4>
              <ul className="landing-footer-list">
                <li>
                  <Link to="/resources/blog#">Blog</Link>
                </li>
                {/* <li>
                  <Link to="#">Property Management</Link>
                </li> */}
                <li>
                  <a href="https://tenant.insurancehero.ca/rent-panda" target="_blank" rel="noopener noreferrer">Tenant Insurance</a>
                </li>
                <li>
                  <a href="https://groverentals.ca/" target="_blank" rel="noopener noreferrer">Property Management</a>
                </li>
                <li>
                  <Link to='/how-it-works/tenant#'>How It Works - Tenant</Link>
                </li>
                <li>
                <Link to='/how-it-works/landlord#'>How It Works - Landlord</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="landing-coperights">
            &#169; 2021 Rent Panda. All rights reserved.
            <Link to="/privacy-policy#" className="ml-2 mr-2">Privacy Policy</Link>
            <Link to="/terms-and-conditions#">Terms of Service</Link>
          </div>
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default withRouter(FooterLandingPage);
