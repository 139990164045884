import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import AppLayout from "../AppLayout";
import "./style.scss";
import { useHistory } from 'react-router-dom';
import PageTitleHeader from "../PageTitleHeader";

const Profile = (props) => {
  const history = useHistory();

  const backToDashboard = () => {
    history.push('/dashboard');
  }
  return (
    <>
      <AppLayout>
        <div className="profile">
          <PageTitleHeader
            title='my profile'

            isArrow={true}
            onClick={() => backToDashboard()}
          />

          <div className="profile-container">
            <div className="profile-container-box">
              <div className="inner-wrapper">
                <div className="profile-container-header">
                  <div
                    className="profile-container-header-name">
                    {props.editProfileList.data &&
                      Object.values(props.editProfileList.data.name).join("").toString()} {""}
                  </div>
                  <div
                    className="profile-container-header-email">
                    Landlord - {" "}
                    {props.editProfileList.data &&
                      Object.values(props.editProfileList.data.email).join("").toString()} {"-"} {props.editProfileList.data &&
                        Object(props.editProfileList.data.phone).toString()}
                  </div>
                </div>
                <Row className='profile-option-container'>

                  <Col className="profile-option-box" style={{ minHeight: '170px' }} onClick={() => history.push('/editprofile')}>
                    <img
                      width="25.5"
                      height="25.5"
                      left="12.5%"
                      right="12.5%"
                      top="12.5%"
                      bottom="12.5%"
                      alt="Group"
                      src={require("../../../_assets/images/check-with-box-active-icon.svg")}
                      className="d-inline-block align-top"
                    />
                    <span
                      className="profile-option-box-title-typography"
                    >
                      Edit Profile
                      </span>
                    <span
                      className="et-path-arrow-icon"
                    >
                      <img
                        width="11"
                        height="18"
                        alt="Path"
                        src={require("../../../_assets/images/path.svg")}
                        className="d-inline-block align-top"
                      />
                    </span>
                  </Col>

                  <Col className="profile-option-box" style={{ minHeight: '170px' }} onClick={() => history.push('/changepassword')}>
                    <img
                      width="25.5"
                      height="25.5"
                      left="12.5%"
                      right="12.5%"
                      top="12.5%"
                      bottom="12.5%"
                      alt="reset"
                      src={require("../../../_assets/images/setting-active-icon.svg")}
                      className="d-inline-block align-top"
                    />
                    <span
                      className="profile-option-box-title-typography"
                    >
                      Reset Password
                      </span>
                    <span
                      className="et-path-arrow-icon"
                    >
                      <img
                        width="11"
                        height="18"
                        alt="Path"
                        src={require("../../../_assets/images/path.svg")}
                        className="d-inline-block align-top"
                      />
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}

const select = (state) => ({
  editProfileList: state.editProfile.editProfileList,

});
export default connect(select)(Profile);
