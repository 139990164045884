import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { getAPI, postAPI } from '../../../../Api';
import { logger, toaster } from '../../../../Utils';
import Cookies from 'js-cookie';
import ChatBox from '../../../components/Message/ChatBox';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMessageData,
  setLeaseData,
  setConversationId,
  setExistingShowingRequestId,
  setExistingShowingSentBy,
  setExistingTenancyApplicationId,
  setIsTenancyApplicationReceived,
  setIsBlocked,
  setIsProfileCompleted,
  setReceiverData,
  setIsLeaseExists,
  clearMessageData,
} from '../../../../Store/actions/MessageDetailAction';

import io from 'socket.io-client';
import { SOCKET_URL } from '../../../../Constants/endPoints';
import { Loader } from '../../../components';
import { getMessagesAPI } from '../../../Landlord/PropertyDetails/helper';
import './style.scss';
import { updateRawData } from '../../../../Store/actions/RawDataAction';
import { getTenantProfile } from '../../../components/Message/helper';
import { getPropertyList, getLastIndexOfMessageType } from './helper';
import TenantPropertyList from './TenantPropertyList';
import './style.scss';
import useWindowDimensions from '../../../hooks/WindowSize';

const log = logger('MessageTenant');
const MessageTenant = (props) => {
  const {
    componentLoad,
    setShowingRequest,
    setConfirmShowing,
    setViewingRequestId,
    setApply_for_tenancy,
    setLeasePdfViewScreen,
    setReSchedule,
    is_reSchedule,
    setShowPropertyDetails,
    isDashboard = false,
    list = [],
    onProperySelect,
  } = props;

  const [isConcentVerified, setIsConcentVerified] = useState(false);
  const [message, setMessage] = useState('');
  const [isMessageEmpty, setIsMessageEmpty] = useState(true);
  const [wrappedText, setWrappedText] = useState('');
  const [is_fetching_data, setFetching_data] = useState(false);
  const { selectedPropertyForMessage = {} } = useSelector(
    (state) => state.rawData
  );
  const dispatch = useDispatch();
  const messageData = useSelector(
    (state) => state.messageDetailsReducer.messageData
  );
  const conversation_id = useSelector(
    (state) => state.messageDetailsReducer.conversationId
  );
  const propertyDetails = useSelector(
    (state) => state.propertyDetailsReducer.propertyDetails
  );
  const is_blocked = useSelector(
    (state) => state.messageDetailsReducer.isBlocked
  );
  const role = Number(Cookies.get('role'));
  const property_id =
    (propertyDetails && propertyDetails.property_id) ||
    Number(props.match.params.property_id);
  const property_details_id =
    (propertyDetails && propertyDetails.property_details_id) ||
    Number(props.match.params.property_details_id);

  const headerContent = useSelector(
    (state) => state.messageDetailsReducer.headerContent
  );

  const [rowCount, setRowCount] = useState(1);
  const { width } = useWindowDimensions();
  const [isMobile] = useState(width < 750);

  const [showPropertyList, setShowPropertyList] = useState(false);
  let socket = useState(null);
  const propertyList = list;
  useEffect(() => {
    (async () => {
      await getTenantProfile();
    })();

    return () => {
      return () => {
        socket.removeAllListeners();
      };
    };
  }, []);

  useEffect(() => {
    if (propertyList?.length) {
      if (!isMobile) {
        onProperySelect(propertyList[0]);
        setShowPropertyList(true);
      } else {
        setShowPropertyList(true);
      }
    }
  }, [isMobile, propertyList?.length]);

  useEffect(() => {
    if (selectedPropertyForMessage?.property_id && isMobile) {
      setShowPropertyList(false);
    }
  }, [selectedPropertyForMessage?.property_id]);

  useEffect(() => {
    socket = io(SOCKET_URL);
    if (conversation_id) {
      socket.on(
        'conversation-' + conversation_id + ':MessageEvent',
        (data, data1) => {
          getSocketDataCall();
        }
      );
    }
  }, [conversation_id]);

  const getSocketDataCall = () => {
    getMessages(false);
    getTenantProfile();
  };

  const getMessages = async (clearAll = true) => {
    if ((property_details_id && componentLoad) || isDashboard) {
      const url = '/tenant/get-messages/' + property_details_id;
      // const messagesResponse=await getMessagesAPI(url)
 
      getAPI(url)
        .then((response) => {
          const res = response.data.data;
          updateRawData({ consentStatus: res?.consentStatus });
          dispatch(setMessageData(res.messageData));
          dispatch(setLeaseData(res.leaseDetail));
          dispatch(setConversationId(res.conversationId));
          dispatch(setExistingShowingRequestId(res.existingShowingRequestId));
          dispatch(setExistingShowingSentBy(res.existingShowingSentBy));
          dispatch(
            setExistingTenancyApplicationId(res.existingTenancyApplicationId)
          );
          dispatch(
            setIsTenancyApplicationReceived(res.isTenancyApplicationReceived)
          );
          dispatch(setIsBlocked(res.is_blocked));
          dispatch(setIsProfileCompleted(res.is_profile_completed));
          dispatch(setIsLeaseExists(res.isLeaseExists));
          dispatch(setViewingRequestId(res.viewingRequestId));
          setTimeout(() => {
            setFetching_data(false);
          }, 500);
        })
        .catch((error) => {
          setTimeout(() => setFetching_data(false), 500);
        });
    }
  };

  const getRowCount = (textArea) => {
    const lines = textArea.value.split(/\r|\r\n|\n/);
    const isTextAreaEmpty = textArea.value.trim().length === 0;
    const isTextAreaSpilled =
      textArea.nextSibling.offsetWidth > textArea.offsetWidth;
    let rowsCount = lines.length > 2 ? 3 : lines.length;
    if (!isTextAreaEmpty && isTextAreaSpilled) {
      rowsCount =
        textArea.nextSibling.offsetWidth >= textArea.offsetWidth * 2 ? 3 : 2;
    }
    setIsMessageEmpty(isTextAreaEmpty);
    setWrappedText(lines[0]);
    return rowsCount;
  };
  const handleChange = (event) => {
    const textArea = event.target;
    let rowsCount = getRowCount(textArea);
    if (event.type === 'paste') {
      setTimeout(() => {
        rowsCount = getRowCount(textArea);
        setRowCount(rowsCount);
        setMessage(textArea.value);
      }, 100);
    } else {
      setRowCount(rowsCount);
      setMessage(event.target.value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        return;
      } else if (event.target.value.trim() !== '') {
        sendMessage(event);
      }
    }
  };

  const sendMessage = (event, props) => {
    event.preventDefault();
    if (message.trim().length > 0) {
      //validate(MessageValidationSchema)
      const data = {
        property_id: property_id,
        property_details_id: property_details_id,
        message: message.trim(),
      };
      const url = '/tenant/send-message';
      postAPI(url, data)
        .then((response) => {
          if (response.status !== 200) {
            toaster('error', response.response.data.message);
          } else if (response.status === 200) {
            getMessages(conversation_id);
            setMessage('');
            setRowCount(1);
            setIsMessageEmpty(true);
          }
        })
        .catch((error) => {});
    } else {
      setMessage('');
      setRowCount(1);
      setIsMessageEmpty(true);
    }
  };

  const blockUser = (status) => {
    let url = '/tenant/block-landlord';
    if (status === 'unblock') {
      url = '/tenant/unblock-landlord';
    }
    const data = {
      conversation_id: conversation_id,
    };
    postAPI(url, data)
      .then((response) => {
        log.info('data', response.data);
        getMessages();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (property_details_id) {
      getMessages();
      (async () => {
        const res = await getAPI(
          `/tenant/profile?conversation_id=${conversation_id}`
        );
        const profile = res.data?.data;
        if (profile) {
          setIsConcentVerified(profile.basic_info.is_certn_verified);
        }
      })();
    }
  }, [property_id, property_details_id, conversation_id]);
console.log({showPropertyList})
  return (
    <div className="profile">
      <div className="profile-container">
        <div className="profile-container-box property-details">
          <Row>
            {is_fetching_data ? (
              <Col md={12}>
                <div
                  className="chat-wrapper"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Loader />
                </div>
              </Col>
            ) : (
              <div className={'d-flex'} style={{ width: '100%' }}>
                {!!propertyList?.length && isDashboard && showPropertyList && (
                  <div style={{ flex: isMobile ? 1 : 0.3 }}>
                    <TenantPropertyList
                      propertyList={propertyList}
                      onProperySelect={onProperySelect}
                      isMobile={isMobile}
                    />
                  </div>
                )}
                {(!showPropertyList || !isMobile) && (
                  <div style={{ flex: 1 }}>
                    <ChatBox
                      setShowTenantList={() => {
                        setShowPropertyList(true);
                      }}
                      showTenantList={showPropertyList}
                      isDashboard={isDashboard}
                      setShowPropertyDetails={setShowPropertyDetails}
                      is_blocked={is_blocked}
                      isConcentVerified={isConcentVerified}
                      messageData={messageData}
                      headerContent={headerContent}
                      conversation_id={conversation_id}
                      setReSchedule={(flag) => setReSchedule(flag)}
                      is_reSchedule={is_reSchedule}
                      grid={12}
                      role={role}
                      sendMessage={(data) => sendMessage(data)}
                      message={message}
                      handleChange={(data) => handleChange(data)}
                      blockUser={(data) => blockUser(data)}
                      setShowingRequest={setShowingRequest}
                      setConfirmShowing={setConfirmShowing}
                      setViewingRequestId={setViewingRequestId}
                      set_tenancy={setApply_for_tenancy}
                      setLeasePdfViewScreen={setLeasePdfViewScreen}
                      rowCount={rowCount}
                      isMessageEmpty={isMessageEmpty}
                      handleKeyPress={handleKeyPress}
                      handleTextWrap={wrappedText}
                    />
                  </div>
                )}
              </div>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MessageTenant);
