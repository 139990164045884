import React, { useState } from "react";
import "./style.scss";
import { AppLayout, PageTitleHeader, Calender, RequestShowing, ConfirmShowing } from "../../components";
import { logger } from "../../../Utils";

const log = logger("Showings");
const Showings = (props) => {
  const [is_showingRequest, setShowingRequest] = useState(false);
  const [is_confirmShowing, setConfirmShowing] = useState(false);
  const [is_reSchedule, setReSchedule] = useState(false);
  const [calenderData, setCalenderData] = useState(null);

  log.info('is_confirmShowing', is_confirmShowing);
  log.info('calenderData', calenderData);

  return (
    <>
      <AppLayout>
        <div className="profile">
          <PageTitleHeader
            title='showings'

          />

          <div className="profile-container">
            <Calender
              setConfirmShowing={(flag) => {debugger; setConfirmShowing(flag)}}
              showingType={'all'}
            />
          </div>
        </div>
      </AppLayout>

      <RequestShowing
        isShow={is_showingRequest}
        title={is_reSchedule ? 'submit a different schedule' : 'creating showing invitation'}
        handleClose={() => setShowingRequest(false)}
        is_reSchedule={is_reSchedule}
        setReSchedule={(flag) => setReSchedule(flag)}
        calenderData={calenderData}
         showingType={'all'}
      />

      <ConfirmShowing
        isShow={is_confirmShowing}
        title={'showing invitation'}
        handleClose={() => setConfirmShowing(false)}
        setShowingRequest={(flag) => setShowingRequest(flag)}
        setReSchedule={(flag) => setReSchedule(flag)}
        calenderData={calenderData}
         showingType={'all'}
      />
    </>
  );
}

export default Showings;
