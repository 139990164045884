import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { Loader, ModalBox, PackageCard } from "../../../components"
import { useHistory } from 'react-router-dom';
import { toaster, logger } from "../../../../Utils"
import { getAPI, postAPI } from "../../../../Api";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentPage, setPaymentSummary } from "../../../../Store/actions/PropertyDetailAction";
import { publishListing } from "./helper";

const log = logger("PromoteListing");
const PromoteListing = (props) => {
  const history = useHistory();
  const { setAddUnit, setIsPaymentSummary } = props
  const [promoDetails, setPromoDetails] = useState(null);
  const [package_id, setPackage_id] = useState(null);
  const [showConfirmPopUp,setShowConfirmPopUp]=useState(null)

  const dispatch = useDispatch();
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_type = propertyDetails && propertyDetails.property_type;
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;

  const getPackage = () => {
    const url = '/landlord/promote-listing-packages';
    getAPI(url)
      .then(response => {
        log.info('listing packages', response.data.data);
        const res = response.data.data;
        setPromoDetails(res)
      })
      .catch(error => {
      });
  }

  log.info('package_id', package_id)

  useEffect(() => {
    getPackage();
  }, [unitDetails]);



  const setPackage = (id) => {
    setPackage_id(id)
    return true
  }

  const onSubmit = (type, id) => {
    id && setPackage(id);
    const url = '/landlord/promote-listing';
    // if (package_id === null) {
    //   toaster('error', 'Please select a package!');
    //   return false;
    // }
    const data = {
      property_id: property_id,
      property_details_id: property_details_id,
      package_id: id || 0
    }
    postAPI(url, data).then(response => {
      if (response.status !== 200 || response.response) {
        if (response.response.status === 422) {
          response.response.data.errors.package_id && toaster("error", response.response.data.errors.package_id[0]);
        } else {
          toaster('error', response.response.data.message);
        }
      } else if (response.status === 200) {
        // toaster('success', response.data.message);

        if (type === 'next') {
          if (property_type === 1) {
            history.push('/dashboard');
          } else {
            dispatch(getCurrentPage(1))
            setAddUnit(true)
          }
        }

        if (type === 'pay') {
          setIsPaymentSummary(true)
          dispatch(setPaymentSummary(response.data.data.payment_summary))
        }
      }
    })
  }
  const onSkip = async () => {
    const res = await publishListing(property_id, property_details_id);
    if (res) {
      setShowConfirmPopUp(true);
    }
   else{
      toaster('error', 'Property Not Published!');
    }
  };


  const onGotIt=()=>{
    setShowConfirmPopUp(false)
    history.push({
      pathname: '/dashboard'
    });
  }
  return (<>
    <div className="inner-wrapper cu-step-form-body" style={{ minHeight: "500px" }}>
      <div className="body-container">
        <div className="body-header">
          <h3>advertise everywhere</h3>
          <span className="save-link" onClick={() => onSubmit('next')}>Save</span>
        </div>
        <Form>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>We will do the work of placing your ad on multiple sites, so that more tenants see it and come to inquire:</Form.Label>
            </Form.Group>
          </Row>
        </Form>

        <Form>
          <Row className="d-flex justify-content-center package-card-outer">
            {promoDetails ? promoDetails.map((data, i) => {
              return (
                <PackageCard
                  key={'package_' + i}
                  title={data.name}
                  price={'$' + data.price}
                  is_active={(data.id === 2) ? true : false}
                  items={data.items}
                  // onClick={() => setPackage_id(data.id)} />)
                  onClick={() => onSubmit('pay', data.id)} />)
            }):<Loader/>}
          </Row>
        </Form>

        <div className="d-flex justify-content-md-end justify-content-center">
          <span data-description="Landlord published listing" id="LNK-listing-step-6-publish" className="click-here" onClick={onSkip}>Skip and Publish</span>
        </div>
      </div>
    </div>
    <ModalBox
      show={showConfirmPopUp}
      onHide={() =>{}}
      size="sm"
      actionbuttontitle={'got it'}
      closeButtonHeader={false}
      buttonaction={onGotIt}
    >
        <h4>you're all done!</h4>
      <p>your listing will be published as soon as our team approves it. we will email you when it's live (usually 1-3 hours)!</p>
    </ModalBox>


    <div className="container-footer cu-step-form-footer">
      <Button variant="secondary" onClick={() => dispatch(getCurrentPage(5))}>Back</Button>
      {/* <Button variant="success" onClick={() => onSubmit('pay')}>Pay Now</Button> */}
    </div>


  </>);
}

export default PromoteListing;
