import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import "./style.scss";
import { AppLayout, PageTitleHeader, RequestShowing, ConfirmShowing, TenancyForm } from "../../components";
import { logger, toaster } from "../../../Utils";
import { getAPI } from "../../../Api";
import { Nav } from "react-bootstrap";
import TenantPropertyDetails from "./TenantPropertyDetails";
import { MessageTenant } from "./MessageTenant.js";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { setPropertyDetail } from "../../../Store/actions/PropertyDetailAction";
import { getCalenderData, setHeaderContent, setReceiverData, setTenancyApplicationId, setViewingRequestId } from "../../../Store/actions/MessageDetailAction";
import { LeasePdfView } from '../../components/LeaseManagement/CreateLeaseSteps';
import { isRentedToTenant } from "./helper";
import { Redirect } from 'react-router'

const log = logger("RootTenantPropertyDetails");
const RootTenantPropertyDetailsLegacyRedirect = (props) => {
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [property_slug, setProperty_slug] = useState('');
    const dispatch = useDispatch();

    const receiverData = useSelector(state => state.messageDetailsReducer.receiverData);
    const headerContent = useSelector(state => state.messageDetailsReducer.headerContent);
  
   useEffect(() => {
        if (property_details_id) {
            const url = '/tenant/' + property_details_id + '/property-unit-details';
            getAPI(url).then(response => {
                if (response.status !== 200) {
                    toaster('error', response.response.data.message);
                } else if (response.status === 200) {
                    const res = response.data.data;
                    
                    let addressLine = res.address_line_1;
                    const specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";

                    for (var i = 0; i < specialChars.length; i++) {
                      addressLine = addressLine.replace(new RegExp("\\" + specialChars[i], "gi"), "");
                    }
                  
                    const newUrl = '/for-rent/' + res.property_type + '/' + res.city.toLowerCase() + '/' + res.province.toLowerCase() + '/' + res.bedroom + '-bedroom/' + encodeURI(addressLine).replace(/%20/g,'+').toLowerCase() + '/' + property_id + '/' + property_details_id + '/' + property_slug;
                  
                    props.history.push(newUrl);
                }
            })
                .catch(error => {
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property_details_id])
  
    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
        setProperty_slug(props.match.params.slug);
    }, [props]);
  
    return (
      <>
      </>
    );
}

export default withRouter(RootTenantPropertyDetailsLegacyRedirect);
