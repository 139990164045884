import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { NavLink, withRouter } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { AppLayout } from "../../components";
import "./style.scss";
import TenantTableComponent from "./TenantTableComponent";
import InactiveUser from './InactiveUser';
import { setFilters } from '../../../Store/actions/ListingAction';
import { setActiveUserFilters, setInactiveUserFilters } from '../../../Store/actions/UserAction';
import { setCouponFilters } from '../../../Store/actions/CouponAction';


const UserManagement = (props) => {
  const [userDataType, setUserDataType] = useState('all-user');
  const slug = props.match.params.slug;

  const dispatch = useDispatch()
  const pathName = window.location.pathname
  useEffect(() => {
      if(!pathName.includes('listing-management')){
          dispatch(setFilters({
              searchCity: '',
              searchAddress: '',
              searchLandlord: '',
              searchTenant: '',
              searchRentpandaId: '',
              is_verified: false,
              sort_by_price_order: '',
              sort_by_status: ['all'],
              filterByExpiryDate: ''
          }));
      }
      if (!pathName.includes('coupon-management')) {
        dispatch(setCouponFilters({
          coupon_name: '',
          created_date: 'desc',
          no_of_usage: '',
          total_no_of_usage: '',
          start_date: '',
          expiry_date: '',
          coupon_code: '',
          coupon_value: '',
        }))
      }

      const emptyFilter = {
        name: "",
        email: "",
        phone_number: "",
        user_type: "",
        displayType: "View All"
      }
      if (!pathName.includes('all-user')) {
        dispatch(setActiveUserFilters(emptyFilter));
      } else if (!pathName.includes('inactive-user')) {
        dispatch(setInactiveUserFilters(emptyFilter));
      }
  },[pathName, dispatch])


  useEffect(() => {
    if (slug) {
      setUserDataType(slug)
    }
  }, [slug]);

  return (
    <AppLayout>
      <div className="userManagement">
        <Nav fill variant="tabs" className="user-tab-views tab-space-hover pt-2">
          <NavLink to={{ pathname: "/dashboard/all-user" }} className={"nav-link"} activeclassname={'active'} >view all users</NavLink>
          <NavLink to={{ pathname: "/dashboard/inactive-user" }} className={"nav-link"} activeclassname={'active'} >inactive users</NavLink>
        </Nav>

        <div className="table-container">
          <h2>RENT PANDA USERS</h2>
          {userDataType === "all-user" &&
            <TenantTableComponent />
          }
          {userDataType === "inactive-user" &&
            <InactiveUser />
          }
        </div>
      </div>
    </AppLayout>
  )
}

export default withRouter(UserManagement);
