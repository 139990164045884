import React, { useState, useEffect } from "react";
import "./style.scss";
import { Container, Row, Col, Navbar, NavDropdown, Image, Button } from "react-bootstrap";
import { AppLayout, PageTitleHeader, PropertyCard, Loader, ModalBox } from "../../components"
import { useHistory, withRouter } from 'react-router-dom';
import { logger } from "../../../Utils";
import { getAPI } from "../../../Api";
// import Cookies from "js-cookie";
import Bed from '../../../_assets/images/bed.svg';
import Bath from '../../../_assets/images/bath.svg';
import Home from '../../../_assets/images/home.svg';

import { useDispatch } from 'react-redux';
import { setPropertyDetail, getUnitDetail, getCurrentPage } from "../../../Store/actions/PropertyDetailAction";
import { Verification } from "./Verification";

const log = logger("LandlordDashboard");
const LandlordDashboard = (props) => {
  const history = useHistory();
  const [propertyLists, setPropertyLists] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [is_verify, setVerify] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const [activeKey, setActiveKey] = useState('new_to_old');
  // const accessToken = Cookies.get("access_token") ? Cookies.get("access_token") : null;
  const dispatch = useDispatch();

  useEffect(() => {
    let url = '/landlord/property-listing?sort_by=post_date&sort_order=' + activeKey;
    if (activeKey === "address_desc" || activeKey === "address_asc") {
      url = "/landlord/property-listing?sort_by=address&sort_order=" + activeKey;
    }
    getData(url)
  }, [is_verify, activeKey])

  const getData = (url) => {
    getAPI(url).then(response => {
      const res = response.data.data;
      log.info(res);
      setPropertyLists(res)
    })
      .catch(error => {
      });
  }

  const createListing = () => {
    history.push('/create-listing');
    dispatch(getCurrentPage(0));
    dispatch(setPropertyDetail(null));
    dispatch(getUnitDetail(null));
  }

  const propertyDetails = (details, path) => {
    let url = `/property-details/${details.property_id}/${path}`;
    if (details.property_details_id) {
      url = `/property-details/${details.property_id}/${details.property_details_id}/${path}`;
    }else{
      dispatch(getUnitDetail(null));
    }
    props.history.push({
      pathname: url,
      state: { backPath: props?.history?.location?.pathname }
    });
  }

  const handleDropdownSelect = (key) => {
    setActiveKey(key);
  }

  const formattedDetails = (data) => {
    const {
      unit_no,
      bedroom,
      price_per_month,
      property_type,
      is_shared_bathroom,
      bathroom
    } = data;
    const bedRoom =
      bedroom &&
      (parseInt(bedroom) > 1 ? bedroom + " Bedrooms" : bedroom + " Bedroom");
    const price = price_per_month && `$${price_per_month}/Month`;
    
    const propertyTypes= {
      1 : 'Full Property',
      2 : 'Unit',
      3 : 'Room for Rent'
    }

    // return ${bedroom};
    return (
      <div style={{ display: "flex" , fontSize:16,fontWeight:500,color:'black'}}>
        {!! bedroom &&<div style={{ display: "flex" }}>
          <Image src={Bed}/>
          &nbsp;
          <span>{bedroom} Bed</span>
        </div>}
       {!!bathroom && <div style={{ display: "flex" ,marginLeft:20}}>
       <Image src={Bath}/>
          &nbsp;
          <span>{bathroom} Bath</span>
        </div>}
        <div style={{ display: "flex" ,marginLeft: !!bathroom ? 20 : 0}}>
        <Image src={Home}/>
          &nbsp;
          <span>{propertyTypes[property_type]}</span>
        </div>
      </div>
    );
    // let details = '';
    // if (property_type === 3) {
    //   if (is_shared_bathroom && is_shared_bathroom === 1) {
    //     details = `Room • Shared Bathroom`;
    //   } else {
    //     details = `Room • Private Bathroom`;
    //   }
    //   if (price) {
    //     details += ` • ${price}`;
    //   }
    // } else {
    //   if (property_type === 2) {
    //     details = unit_no ? (isNaN(unit_no) ? unit_no : `Unit ${unit_no}`) : 'Unit';
    //   } else if (property_type === 1) {
    //     details = `Full Property`;
    //   }
    //   if (bedRoom && price) {
    //     details += ` • ${bedRoom} • ${price}`;
    //   } else if (bedRoom) {
    //     details += ` • ${bedRoom}`;
    //   } else if (price) {
    //     details += ` • ${price}`;
    //   }
    // }
    // return details;
  }

  const onVerifyAction = (data) => {
    setPropertyData(data);
    setModalShow(true);
  }


  const getTitle=(activeKey)=>{
    const title=activeKey.split("_").join(" ")
    return title==="address asc" ? 'A - Z' : title==='address desc' ? 'Z - A':title
  }
const title=getTitle(activeKey)

  return (
    <>
      <AppLayout>
        <div className="profile">
          <PageTitleHeader
            title='for rent'
          // mobileTitle='for rent'
          />

          <div className="profile-container">
            <div className="profile-container-box">
              <div className="ln-create-btn-area"
              // style={{
              //   position: "relative",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "space-between",
              //   zIndex: "20",
              //   marginBottom:'16px'
              // }}
              >
                <Navbar className="filter-nav">
                  <Navbar.Collapse className="d-flex justify-content-between">
                    <Navbar.Text className="mf-10">{title === 'A - Z' || title === 'Z - A' ? 'address' : 'posted date'}:</Navbar.Text>
                    <NavDropdown title={title} id="nav-dropdown">
                      <NavDropdown.Item onClick={(e) => handleDropdownSelect('new_to_old')}>posted date: new to old</NavDropdown.Item>
                      <NavDropdown.Item onClick={(e) => handleDropdownSelect('old_to_new')}>posted date: old to new</NavDropdown.Item>
                      {/* <NavDropdown.Item onClick={(e) => handleDropdownSelect('low_to_high')}>price: low to high</NavDropdown.Item>
                      <NavDropdown.Item onClick={(e) => handleDropdownSelect('high_to_low')}>price: high to low</NavDropdown.Item> */}
                      <NavDropdown.Item
                        onClick={(e) => handleDropdownSelect("address_asc")}
                      >
                        alphabetical: A - Z
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        onClick={(e) => handleDropdownSelect("address_desc")}
                      >
                        alphabetical: Z - A
                      </NavDropdown.Item>

                    </NavDropdown>
                  </Navbar.Collapse>
                </Navbar>

                <button className="create-listing-black-button" onClick={() => createListing()}>
                  <span className="d-sm-inline-block" data-description="Landlord initiated new listing" id="BTN-create-new-listing">create new</span>
                  {/* <span className="d-md-none d-lg-none d-xl-none">add Listing</span> */}
                </button>
              </div>

              <Container fluid>
                <Row>
                  {propertyLists ?
                    propertyLists.length > 0 ?
                      propertyLists.map((list, i) => {
                        return (
                          <PropertyCard
                            key={"propery_" + i}
                            inquiries={list.new_inquiry}
                            onClickInquiries={() => propertyDetails(list, 'messages')}
                            viewCount={list.view_count}
                            image_url={list.image_url}
                            approval={list.is_promoted && list.formatted_status === 'approved' ? "promoted"  : (list.formatted_status ? list.formatted_status : 'in progress')}
                            isVerifed={list.is_verified}
                            verification_pending={list.verification_pending}
                            onVerifyAction={() => onVerifyAction(list)}
                            title={list.address}
                            details={formattedDetails(list)}
                            onClick={() => propertyDetails(list, 'unit-details')}
                            {...list}
                          />
                        )
                      }) :
                      <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '0 0 1rem' }}>
                        <Image src={require("../../../_assets/images/RP-Illustration.svg")} style={{marginBottom: '1.5rem', maxHeight: '40vh', maxWidth: '50vw'}} />
                        <p style={{ fontSize: '25px', color: '#979797' }}>You’ve Got No Places Currently For Rent. </p>
                        <Col md='3'><Button data-description="Landlord initiated new listing" id="BTN-create-new-listing" block onClick={() => createListing()}>Create New Listing</Button></Col>
                      </Col>
                    : <Loader />}

                </Row>
              </Container>
            </div>
          </div>
        </div>
      </AppLayout>

      <Verification
        isShow={is_verify}
        handleClose={() => setVerify(false)}
        propertyData={propertyData} />

      <ModalBox
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        actionbuttontitle={'get verified now'}
        buttonaction={() => {
          setModalShow(false);
          setVerify(true);
        }}
        backbuttontitle={'get verified later'}
        backaction={() => {
          setModalShow(false);
        }}
      >
        <h4>verified listings get rented<br/> faster and can be rented<br/> for more $$.</h4>
        <p>verifying your property is free and only takes 30 minutes. all we need to do is come by to take photos of the place, to make sure what tenants see is what they get.</p>
      </ModalBox>
    </>
  );
}

export default withRouter(LandlordDashboard);
