import Axios from "axios";
import { API_URL } from "../Constants/endPoints";
import { logger } from "../Utils";
import Cookies from "js-cookie";
import history from '../history';

const log = logger("BaseApi");
// const handleError = (error) => {
//     log.info("error=====>", error);
//     if (error.response !== undefined) {
//         if (error.response.data.code === 403) {
//             return error.response.data.code;
//         }
//         return error.response.data.code;
//     }
// }
export default class BaseApi {
    static async get(endpoint) {
        const res = await Axios.get(API_URL + endpoint, {
            headers: { Authorization: `Bearer ${Cookies.get("access_token")}` }
        })
            .then(response => {
                return response;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    history.push("/login?unauthorized");
                    history.go(0)
                }
                return error;
            });
        return await res;
    }

    static async put(endpoint, payload) {
        const res = await Axios.put(API_URL + endpoint, payload, {
            headers: { Authorization: `Bearer ${Cookies.get("access_token")}` }
        })
            .then(response => {
                return response;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    history.push("/login?unauthorized");
                    history.go(0)
                }
                return error;
            });
        return await res;
    }

    static async post(endpoint, payload, headers) {
        const res = await Axios.post(API_URL + endpoint, payload, {
            headers: { Authorization: `Bearer ${Cookies.get("access_token")}` }
        })
            .then(response => {
                log.info("post response", response)
                return response;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    history.push("/login?unauthorized");
                    history.go(0)
                }
                log.info("post error", error)
                return error;
            });
        return await res;
    }

    static async delete(endpoint) {
        const res = await Axios.delete(API_URL + endpoint, {
            headers: { Authorization: `Bearer ${Cookies.get("access_token")}` }
        })
            .then(response => {
                return response;
            })
            .catch(error => {
                if (error.response.status === 401) {
                    history.push("/login?unauthorized");
                    history.go(0)
                }
                return error;
            });
        return await res;
    }
}