import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Image } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Carousel from "react-multi-carousel";
import { Loader } from "../../App/components";
import "./style.scss";
const placeHolderImg = require("../../_assets/images/placeholder.jpg");

const ArticlesList = (props) => {
  const {
    articlesList,
    colCount,
    showLoader,
    responsive,
    isDesktop,
    numberOfCards,
  } = props;

  const articlesBox = () => {
    return articlesList.length > 0 ? (
      articlesList.slice(0, 3).map((article, i) => {
        return (
          <Col key={"article" + article.id} md={colCount} className="grid mb-4">
            <div className="resources-box">
              <div className="resources-box-image">
                {/* <Image className="grid-img" src={article.image_url ? article.image_url : placeHolderImg} alt="grid-img" /> */}
                <LazyLoadImage
                  effect="blur"
                  className="grid-img"
                  src={article.image_url ? article.image_url : placeHolderImg}
                  alt="grid-img"
                />
              </div>
              <h4 className="grid-name">{article.headline}</h4>
              <p className="grid-content">{article.excerpt}</p>
              <div className="mt-auto">
                <Nav.Link
                  className="date-text"
                  href={`/resources/blog/preview/${article.id}`}
                >
                  Read more
                  <Image
                    className="pl-2 next"
                    src={require("../../_assets/images/arrow-forward.svg")}
                    alt="View More"
                  />
                </Nav.Link>
              </div>
            </div>
          </Col>
        );
      })
    ) : showLoader ? (
      <Loader />
    ) : (
      <h2>No Data Found</h2>
    );
  };

  return (
    <section>
      <Container>
        <div className="section-head d-flex justify-content-between align-items-end">
          <div>
            <h2 className="subHead">Recent Blogs</h2>
          </div>
          <div>
            <Link to="/resources/landlord/blogs" className="btn btn-link" >View More &#62; </Link>
          </div>
        </div>
        <Row>
          {articlesList && articlesList.length >= numberOfCards && (
            <Col className="text-right viewMore col-auto">
              <Nav.Link className="date-text" href={`/resources/blog`}>
                View More
                <Image
                  className="pl-2 next"
                  src={require("../../_assets/images/arrow-forward.svg")}
                  alt="View More"
                />
              </Nav.Link>
            </Col>
          )}
        </Row>

        <Carousel
          responsive={responsive}
          infinite={isDesktop ? false : true}
          partialVisible={isDesktop ? false : true}
          removeArrowOnDeviceType={["superLargeDesktop", "desktop"]}
          swipeable={numberOfCards === 1}
          showDots={true}
          draggable={true}
          autoPlay={isDesktop ? false : true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          dotListClass="landlord-resources-dot-list"
          arrows={numberOfCards !== 1}
        >
          {articlesList && articlesBox()}
        </Carousel>
      </Container>
    </section>
  );
};

export default ArticlesList;
