import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import './style.scss';
import { AppLayout, PageTitleHeader, RequestShowing, ConfirmShowing, TenancyForm } from "../../App/components";
import {
  UnitAddress,
  UnitDescription,
  UnitImageDisplay,
  SimilarPropertyList,
  Loader,
  ModalBox,
} from '../../App/components';
import { EditButton, LargeButton } from "../../App/components";
import ImportToolPreviewUnitDetails from "./ImportToolPreviewUnitDetails";
import ImportToolPreviewUnitDescription from "./ImportToolPreviewUnitDescription";
import ImportToolPreviewUnitAddress from "./ImportToolPreviewUnitAddress";
import { getGalaryItems } from '../../App/Tenant/TenantPropertyDetails/helper';
import { logger, toaster } from '../../Utils';
import { getAPI, postAPI } from '../../Api';
import ReactPlayer from 'react-player/lazy';
import StaticMap from '../../App/components/StaticMap';
import { Col, Row } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import animationData from '../../_assets/lotties/importToolLoader.json'

import {
  setPropertyDetail,
  getUnitDetail,
} from '../../Store/actions/PropertyDetailAction';

const log = logger('ImportToolPreview');

const ImportToolPreview = (props) => {
  const { callbackRequestShowing, mapHeight = '70vh', isFromRedux ,isDashboard} = props;
  
  const [imagesGallary, setImagesGallary] = useState(null);
  const [is_showGallery, setShowGallery] = useState(false);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  
  const [importPreviewId, setImportPreviewId] = useState(false);
  const [importConfirmUrl, setImportConfirmUrl] = useState(false);
  
  const [previewDetails, setPreviewDetails] = useState(null);
  const [previewStillInQueue, setPreviewStillInQueue] = useState(null);
  
  useEffect(() => {
    setImportPreviewId(Number(props.match.params.preview_id));
    getPreviewData();
    setImportConfirmUrl('/import/confirm/' + importPreviewId);
  }, [importPreviewId]);
  
  const viewGallery = () => {
    setShowGallery(true);
  };
  
  const [showVideo, setshowVideo] = useState({});
  
  const createListing = () => {
    
  }
  
  const getPreviewData = () => {
    setLoader(true);
    setPreviewDetails(null);
    setImagesGallary(null);
    
    if(importPreviewId != false && (previewDetails == null || (previewDetails != null && typeof previewDetails.id != 'undefined' && previewDetails.id != importPreviewId))) {
      const url = '/import_tool/get_preview_data/' + importPreviewId;
      getAPI(url)
        .then((response) => {
          console.log(response);
        
          if (response.status !== 200) {
            toaster('error', response.response.data.message);

            if (response?.response?.status_code === 400) {
              setTimeout(() => {
                props.history.push('/');
              }, 1000);
            }
          } else if (response.status === 200 && response.data.status_code === 200 && response.data.status == true) {
            // Success.
            const res = response.data.data;
            setPreviewDetails(res.previewData);
            setPreviewStillInQueue(false);
                
            const galaryItems = getGalaryItems(
              res.previewData?.images,
            );
            setImagesGallary(galaryItems);
            setLoader(false);
          } else if (response.status === 200 && response.data.status == false && response.data.message == "Preview data still loading.") {
            setPreviewStillInQueue(true);
            setLoader(true);
                
            // Re-attempt in 10s.
            setTimeout(function() {
                getPreviewData();
            }, 10000);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
     }
  };
              
  const backToHome = () => {
      props.history.push('/');
  }
              
  let finalAddress = ''
  if (previewDetails?.address) {
      if (previewDetails?.address[previewDetails?.address.trim().length - 1] === ',') {
          finalAddress = previewDetails?.address.replace(previewDetails?.address[previewDetails?.address.trim().length - 1], '')
          if (previewDetails?.property_type === 2) {
              finalAddress = finalAddress + ' - ' + previewDetails?.unit_no
          } else if (previewDetails?.property_type === 3) {
              finalAddress = finalAddress + ' - ' + previewDetails?.unit_no
          }
      } else {
          if (previewDetails?.property_type === 2) {
              finalAddress = previewDetails?.address + ' - ' + previewDetails?.unit_no
          } else if (previewDetails?.property_type === 3) {
              finalAddress = previewDetails?.address + ' - ' + previewDetails?.unit_no
          } else {
              finalAddress = previewDetails?.address
          }
      }
  } else {
      finalAddress = 'property details preview'
  }    
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
            
  return (
    <>
            <AppLayout>
                { !(loader || previewStillInQueue || previewDetails == null) ?
                <div className="import-tool-create-banner">
                  Edit your listing below and then <a href={importConfirmUrl}>click here</a> to sign up and post your ad. You will also be able to edit your listing after creation.
                </div>
                : "" }
                
                <div className="profile">
                    <PageTitleHeader
                        title={finalAddress}
                        isArrow={true}
                        onClick={() => backToHome()}
                    />
              
                    <div className={`profile`}>
                    <div className="profile-container">
                      <div className="profile-container-box property-details">
                        {loader || previewStillInQueue || previewDetails == null ? (
                                <Lottie 
                                isClickToPauseDisabled={true}
                                className="loader-animation"
                                options={defaultOptions}
                                  height={600}
                                  width={600}
                                />
                        ) : (
                          <Row className={`${props.classes}`}>
                            <Col md="12" className="d-flex flex-wrap">
                              {previewDetails?.images && (
                                <UnitImageDisplay
                                  imagesList={previewDetails?.images}
                                  videosList={previewDetails?.videos}
                                  actionDetails={() => viewGallery()}
                                />
                              )}
                            </Col>

                            <Col md={`${props.colSize || '7'}`}>
                              {!props.hideAddress && (
                                <ImportToolPreviewUnitAddress previewDetails={previewDetails} />
                              )}
                              <ImportToolPreviewUnitDetails previewDetails={previewDetails} />
                              <ImportToolPreviewUnitDescription previewDetails={previewDetails} />
                            </Col>

                            <Col md={`${props.colSize} || 5`}  >
                              <div className="inner-wrapper">
                              <Row className="mt-4 mb-2" >
                                <Col md={12}>
                               {isDashboard?   <h6   style={{color:'#888892',fontSize:14}}>
                                    Check out the neighbourhood
                                  </h6>
                                :
                                <h4 className="similar-listing-title text-left">
                                Check Out The Neighbourhood
                              </h4>  
                                }
                                </Col>
                              </Row>
                              {previewDetails?.latitude && previewDetails?.longitude && (
                                <div

                                  style={{
                                    height: mapHeight,
                                    padding: '0',
                                    overflow: 'hidden',
                                    border: '2px solid #ccc',
                                    marginTop:isDashboard?5:0
                                  }}
                                >
                                  <StaticMap
                                    height={mapHeight}
                                    latitude={previewDetails?.latitude}
                                    longitude={previewDetails?.longitude}
                                  />
                                </div>
                              )}
                           </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </div>
                  <ModalBox
                    show={is_showGallery}
                    onHide={() => setShowGallery(false)}
                    size="lg"
                  >
                    {imagesGallary && (
                      <ImageGallery
                        items={imagesGallary}
                        lazyLoad={false}
                        onSlide={() => setshowVideo({})}
                      />
                    )}
                  </ModalBox>
                </div>
            </AppLayout>
        </>
  );
};

export default withRouter(ImportToolPreview);
