import React, { useEffect, useState } from 'react';
import { Image } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { useDispatch } from 'react-redux'; // useSelector
import { setPropertyLists, setLocationList } from '../../Store/actions/LandingPageAction';
import { postAPI, getAPI } from '../../Api';
import { logger } from '../../Utils';
import { HeaderLandingPage, FooterLandingPage } from "../components";
import { HeroSection, LovesMovingAround, ToolsToHelp } from "./components";
import { Helmet } from 'react-helmet';

import './style.scss';
import { Loader } from '../../App/components';
import { getAds } from '../../Utils/Ads';

const log = logger("Search");
const Search = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [propertyData, setPropertyData] = useState({})
    const [ads, setAds] = useState(null)
    // const searchLocation = useSelector(state => state.landingPageReducer.getSearchLocation) || '';
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const ads = await getAds()
            if (ads?.length)
                setAds({ ...ads[0] })

        })()

        const timeout = setTimeout(() => {
            setIsLoading(true);
            const data = {
                // "search_term": searchLocation,
                limit: 6
            }
            postAPI('/tenant/search-property', data).then(response => {
                const res = response.data.data;
                setPropertyData(res)
                dispatch(setPropertyLists(res));
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                log.info(error);
            });
        }, 100)

        return () => clearTimeout(timeout);
    }, [dispatch]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(true);
            getAPI('/cms/rent-panda-cities').then(response => {
                const res = response.data.data;
                const locations = res.map(list => list.city);
                dispatch(setLocationList(res));
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                log.info(error);
            });

        }, 100)
        return () => clearTimeout(timeout);
    }, [dispatch]);

    return (
        <>
        <Helmet>
            <title>
                Home | Rent Panda
            </title>
            <meta name="description" content="Rent Panda is a free property rental 
            platform for tenants and landlords. Find houses, rooms, condos, or apartments 
            for rent with verified listings." />
        </Helmet>
        <div className="landing-page-tenant">
            <HeaderLandingPage />
            <HeroSection />
            <LovesMovingAround isLoading={isLoading} />
            <div style={{ marginBottom: 40, cursor: 'pointer',display:'flex',justifyContent:'center' }}>
                {ads?
                    <a href={ads?.image_link}>
                        <Image src={ads?.image_url} alt="" />
                    </a>
                    : <Loader />}
            </div>
            <ToolsToHelp/>
            <FooterLandingPage />
        </div>
        </>
    )
}

export default withRouter(Search);
