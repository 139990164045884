import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import './style.scss';
import { AppLayout, PageTitleHeader, RequestShowing, ConfirmShowing, TenancyForm } from "../../App/components";
import { EditButton, LargeButton } from "../../App/components";
import { logger, toaster } from '../../Utils';
import { getAPI, postAPI } from '../../Api';
import ReactPlayer from 'react-player/lazy';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row, Button } from 'react-bootstrap';

const log = logger('ImportToolPreview');

const ImportToolConfirm = (props) => {
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  
  const [importPreviewId, setImportPreviewId] = useState(false);
  
  const [previewDetails, setPreviewDetails] = useState(null);
  const [previewStillInQueue, setPreviewStillInQueue] = useState(null);
  
  useEffect(() => {
    setImportPreviewId(Number(props.match.params.preview_id));
    getPreviewData();
  }, [importPreviewId]);
  
  const role = Number(Cookies.get("role"));
  
  useEffect(() => {
    if(typeof role != 'undefined' && role != null && role != false && !(isNaN(role))) {
      // Is logged in, should show the confirm dialog.
    } else {
      // Is not logged in, redirect to auth with a goback to this url.
      Cookies.set('import_id', importPreviewId);
      Cookies.set('should_redirect_to_import', 1);
      
      props.history.push('/signup/landlord');
    }
  }, [role]);
  
  const [showVideo, setshowVideo] = useState({});
  
  const cancelListing = () => {
    backToHome();
  }
  
  const createListing = () => {
    if(previewDetails != null && importPreviewId != false) {
      const url = '/import_tool/create_listing/' + importPreviewId;
      const data = {
      }
      setLoader(true);
      postAPI(url, data).then(response => {
          setLoader(false);

          if(response.data.status == true && typeof response.data.data.listingId !== 'undefined' && response.data.data.listingId > 0 && typeof response.data.data.listingDetailId !== 'undefined' && response.data.data.listingDetailId > 0) {
            props.history.push('/edit-listing/' + response.data.data.listingId + '/' + response.data.data.listingDetailId);
          } else {
            toaster('error', 'Failed to create listing! Please try again!');
          }
      });
    }
  }
  
  const getPreviewData = () => {
    setLoader(true);
    
    if(importPreviewId != false && (previewDetails == null || (previewDetails != null && typeof previewDetails.id != 'undefined' && previewDetails.id != importPreviewId))) {
      const url = '/import_tool/get_preview_data/' + importPreviewId;
      getAPI(url)
        .then((response) => {
          console.log(response);
        
          if (response.status !== 200) {
            toaster('error', response.response.data.message);

            if (response?.response?.status_code === 400) {
              setTimeout(() => {
                props.history.push('/');
              }, 1000);
            }
          } else if (response.status === 200 && response.data.status_code === 200 && response.data.status == true) {
            // Success.
            const res = response.data.data;
            setPreviewDetails(res.previewData);
            setPreviewStillInQueue(false);
                
            setLoader(false);
          } else if (response.status === 200 && response.data.status == false && response.data.message == "Preview data still loading.") {
            setPreviewStillInQueue(true);
            setLoader(true);
                
            // Re-attempt in 10s.
            setTimeout(function() {
                alert('retrying in 10s');
                getPreviewData();
            }, 10000);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
     }
  };
              
  const backToHome = () => {
      props.history.push('/');
  }                          
                
  return (
    <>
        <AppLayout>
            <div className="profile">
                <PageTitleHeader
                    title="Confirm your new listing"
                    isArrow={true}
                    onClick={() => backToHome()}
                />

                <div className={`profile`}>
                  <div className="profile-container">
                    <div className="profile-container-box property-details">
                      {loader || previewStillInQueue || previewDetails == null ? (
                        'Please wait while we pull your listing information!'
                      ) : (
                        <Row className={`${props.classes}`}>
                          <Col md="12" className="d-flex flex-wrap">
                            You are creating a listing for the following property.
                          </Col>

                          <Col md="12" className="d-flex flex-wrap property-preview-card-import">
                            <div className="preview-property-image"><img src={previewDetails?.images[0]} /></div>
                            <div className="preview-property-details">
                              <div className="preview-property-title">{previewDetails?.address}</div>
                              <div className="preview-property-price">${previewDetails?.price_per_month}</div>
                            </div>
                          </Col>
              
                          <Col md="12" className="d-flex flex-wrap property-preview-import-actions">
                            <Button variant="Link" className="btn-create-listing nav-link-active" onClick={createListing}>Create Listing
                              <img className="pl-2 next" src={require("../../_assets/images/next.svg")} alt='create listing' />
                            </Button>
                
                            <Button variant="Link" className="btn-cancel-listing" onClick={cancelListing}>Cancel
                              <img className="pl-2 next" src={require("../../_assets/images/close-icon.svg")} alt='cancel listing' />
                            </Button>
                          </Col>
                        </Row>
                      )}
                  </div>
                </div>
             </div>
          </div>
      </AppLayout>
    </>
  );
};

export default withRouter(ImportToolConfirm);